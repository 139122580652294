<template>
	<div>
		<!-- profileModal -->
		<modal
			class="modal modal1"
			:show="addOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAddModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Student</h3>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div
							class="custom-edit-input col-sm-4"
						>
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="width: 200px; height: 26px"
								v-model="CounselingData.student"
								id="name"
							>
								<option selected disabled value="">Choose student</option>
								<option v-for="(student,idx) in students" :key="idx" :value="student.id">{{student.name}}</option>			
							</select>
						</div>
					</div>
					
					<!-- <div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="CounselingData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="CounselingData.whatsapp_number"
							/>
						</div>
					</div> -->
					<div class="form-group row">
						<label
							for="date"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Counseling Date</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="date"
								class="form-control-plaintext py-0"
								id="date"
								v-model="CounselingData.date"
							/>
						</div>
							<label
							for="time"
							class="py-0 col-sm-2 text-sm col-form-label font-weight-bold"
							> Time</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="time"
								class="form-control-plaintext py-0"
								id="time"
								v-model="CounselingData.time"							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Counseling Link</label
						>
						<div class="custom-edit-input col-sm-4 d-flex">
							<input
								type="text"
								style="width: 300px"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="CounselingData.meet_url"
							/>
						</div>
					</div>					

				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
export default {

	props: ["addOpen", "closeAddModal"],
	data() {
		return {
			options: ["English Basic", "Hindi Basic", "Maths Basic","Science Basic"],
			value: [],
			counselorValue: [],
			counselorList: ["MrsRamya","Mr.Kiran","Mrs.Luffy","Mr.Ace"],
			CounselingData:{
				student:'',
				mobile:'',
				whatsapp_number:'',
				date:'',
				time:'',
				meet_url:'',

			} 
		};
	},
	computed: {
		students() {
			return this.$store.state.StmsData.tableData;
		},
		isLoaded() {
			return this.$store.state.CounsellersData.isLoaded;
		},
		count(){
			return this.$store.state.StmsData.stmsCount;
		},
	},
	methods: {
		profileSave() {
			var error =undefined;
            if(this.CounselingData.student.trim()==''){
				error='Student name must be selected';
			} else if(this.CounselingData.date==''){
				error='Couseling date is required';
			} else if(this.CounselingData.time==''){
				error=' Counseling time is required';
			}else if(this.CounselingData.meet_url.trim()==''){
				error='Enter valid meet link';
			}else if(this.CounselingData.meet_url.length>25){
				error='Training link exceeds max limit';
			}
			if (error) {
				this.$notify({				
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.$store
				.dispatch("setCounselingDetails", {
					counselingData:this.CounselingData
				})
				.then(() =>this.closeAddModal())
				.catch(err => {
					this.$notify({
						title:
							"Failed to Add student",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
			});
			
		},
		fetchSTMSList() {
			this.$store
				.dispatch("fetchSTMSList",{searchTerm:'',itemsPerPage:this.count,startIndex:''})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Stms",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		
	},
	mounted() {
		this.fetchSTMSList();
	},
};
</script>
<style scoped>


.custom-edit-input input {
	
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.modal1 {
	z-index: 1999;
}
.modal2 {
	z-index: 2000;
}
.assessment-text {
	word-wrap: break-word;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}

.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	border-bottom: 1px solid #121619 !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
