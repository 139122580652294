<template>
    <div>
         <modal :show="isOpen" type="notice" @close="closeModal">
		<template v-slot:header>
			User details
		</template>
		<div  >
			<div class="row py-1">
				<div class="col-3 form-control-label">Name: </div>
				<div class="col-9">{{userData.name}}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Email ID: </div>
				<div class="col-9">{{userData.email}}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Address: </div>
				<div class="col-9">{{userData.address}}</div>
			</div>
			<div class="row py-1">
				<div class="col-3 form-control-label">Mobile: </div>
				<div class="col-9">{{ userData.mobile}}</div>
			</div>
		</div>
		<template v-slot:footer>
			<!-- <button class="btn btn-danger" @click.prevent="deleteUser">Delete user</button> -->
			<!-- <button class="btn btn-warning" @click.prevent="$emit('actionEdit')">Edit</button> -->
			<button class="btn btn-primary" @click.prevent="closeModal">OK</button>
		</template>
	</modal>
    </div>
</template>
<script>
export default {
    props:['isOpen','closeModal','userId'],
    data(){
        return{
            userData:{
				name:'',
				email:'',
				address:'',
				mobile:''
			},

        }
    },
    computed:{
        usersData(){
            return this.$store.state.profileData.newUserList;
        },
        selectedUser(){
			return this.usersData.find(item=>item.id==this.userId)
		},

    },
    methods:{
        fetchNewUsersDetails() {
			this.$store
				.dispatch("fetchnewUserDetails",this.userId)
				.then(()=>this.userData=this.selectedUser)
				.catch(err => {
					this.$notify({
						title: "Failed to fetch user details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},

    },
    mounted(){
            this.fetchNewUsersDetails();

    }
     
}
</script>