var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-6 pt-7 pt-lg-8  d-flex flex-column align-items-center"},[_c('span',{staticClass:"mask bg-white"}),_c('div',{staticClass:"container-fluid d-flex flex-column "},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 head-section"},[_c('h3',{staticClass:"text-orange"},[_vm._v("Hey "+_vm._s(_vm.$store.state.profileData.data.name))]),_c('h2',{staticClass:"text-black font-weight-bold"},[_vm._v(" New "+_vm._s(_vm.newStudents)+" students joined in this week, total "+_vm._s(_vm.totalStudents)+" students ")]),_c('div',{staticClass:"row d-flex justify-content-between"},[_c('form',{staticClass:"col-12 col-sm-7"},[_c('div',{staticClass:"form-row mt-4"},[_c('div',{staticClass:"search ml-0 ml-sm-4 col-12 mt-2 mt-sm-0 col-sm-5"},[_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Search","addon-right-icon":"fas fa-search"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('div',{staticClass:"col-12 col-sm-3"},[_c('base-button',{staticClass:"col-12",attrs:{"type":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.search($event)}}},[_vm._v("Search")])],1)])]),_c('div',{staticClass:"col-12 col-sm-4 d-flex justify-content-end mt-5 mt-sm-0 align-items-center"},[_c('base-button',{staticClass:"col-12 col-sm-6",attrs:{"type":"primary","outline":""},on:{"click":function($event){$event.preventDefault();return _vm.addNew()}}},[_vm._v("Add New +")])],1)])])]),_c('card',{staticClass:"mt-3 ",style:(_vm.tableStyle),attrs:{"rounded":""}},[_c('div',{staticClass:"table-responsive"},[_c('base-table',{staticClass:"align-items-center",attrs:{"data":_vm.tableData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('td',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.itemsPerPage * (_vm.currentPage - 1) + index + 1)+" ")]),_c('td',{staticClass:"col-2 col-sm-2 id-row"},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-6 col-sm-4 mr-4 mr-sm-4 pl-0"},[_vm._v(" "+_vm._s(row.student_id)+" ")]),_c('div',{staticClass:"btn col-2",style:(_vm.idButtonStyle),on:{"click":function($event){$event.preventDefault();return _vm.openModal(row.id)}}},[_c('i',{staticClass:"fa fa-angle-right text-white d-flex justify-content-center align-items-center"})])])]),_c('td',{staticClass:"col-4"},[_c('div',{staticClass:"row d-flex align-items-center pl-2"},[_c('div',{staticClass:"col-1"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":(row.profile_picture && row.profile_picture.thumbnail) ? row.profile_picture.thumbnail : 'img/user1.jpg',"width":"40px","height":"40px","alt":""}})]),_c('div',{staticClass:"col-9 ml-1"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"font-weight-bold text-black"},[_vm._v(_vm._s(row.name)+" ")])]),_c('div',{staticClass:"col-12"},[_c('span',[_vm._v("standard "+_vm._s(row.student_class)+" , age "+_vm._s(row.age))])])])])]),( row.transaction !=null)?_c('td',{class:row.transaction.status.includes(
												'pending'
											) ||
											row.transaction.status.includes(
												'failed'
											)
												? 'col-2 text-danger '
												: 'col-2 text-success'},[_vm._v(" "+_vm._s(row.transaction.status)+" ")]):_c('td',{staticClass:"col-1"},[_vm._v("No transaction found")]),_c('td',[(row.transaction !=null)?_c('div',{staticClass:"btn col-2",style:(_vm.idButtonStyle),on:{"click":function($event){$event.preventDefault();return _vm.viewClass(row.id)}}},[_c('i',{staticClass:"fa fa-angle-right text-white d-flex justify-content-center align-items-center"})]):_c('div',{staticClass:"col-2 pl-0"},[_c('span',[_vm._v("No course selected")])])])]}}])},[_c('template',{slot:"columns"},[_c('th',{staticClass:"pl-0"},[_vm._v("Sl")]),_vm._l((_vm.tableHeader),function(header,index){return _c('th',{key:index,staticClass:"text-center font-weight-bold text-black"},[_vm._v(" "+_vm._s(header)+" ")])}),_c('th',[_vm._v("Class Detials")])],2)],2)],1),_c('base-pagination',{staticClass:"mt-5",attrs:{"perPage":_vm.itemsPerPage,"total":_vm.count,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"row mt-4 d-flex justify-content-around"},_vm._l((_vm.dashboardData),function(data,index){return _c('div',{key:index,staticClass:"card-sm custom-card rounded shadow bg-white py-3 my-3 my-sm-0 py-sm-3 col-sm mx-2 col-12"},[_c('div',{class:data.head === 'Payment Pending'
										? 'num-card col-3  ml-3 rounded text-center'
										: data.head === 'Demo Class Request'
										? 'num-card col-3  ml-3 rounded text-center'
										: 'num-card col-3  ml-3 rounded text-center',style:(data.head === 'Payment Pending'
										? 'background-color:#F35D94'
										: data.head === 'Demo Class Request'
										? 'background-color:#9B51E0'
										: 'background-color:#9EBF6D')},[_c('h3',{staticClass:"py-2 mt-2 text-white font-weight-bold text-nowrap"},[_vm._v(" "+_vm._s(data.count)+" ")])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"font-weight-bold text-nowrap"},[_vm._v(_vm._s(data.head))])]),_c('div',{staticClass:"col"},[_c('span',{staticClass:"text-sm nowrap"},[_vm._v(_vm._s(data.body))])])]),(data.head=='New students'||data.head=='Demo Class Request')?_c('div',{staticClass:"btn col-2",on:{"click":function($event){$event.preventDefault();return _vm.redirectTo(data.routeId)}}},[_c('i',{staticClass:"fa fa-angle-right"})]):_vm._e()])])}),0)],1),(_vm.notification)?_c('div',{staticClass:"col-2 notif-section"},[_c('Notification',{attrs:{"count":_vm.count,"current-page":_vm.currentPage}})],1):_vm._e()])])]),(_vm.isOpen)?_c('Modal',{attrs:{"isOpen":_vm.isOpen,"closeEditModal":_vm.closeEditModal,"student-id":_vm.studentId}}):_vm._e(),(_vm.addOpen)?_c('ModalTwo',{attrs:{"addOpen":_vm.addOpen,"closeAddModal":_vm.closeAddModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }