<template>
	<div>
		<!-- profileModal -->
		<modal
			class="modal modal1"
			:show="addOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAddModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Teacher</h3>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row ">
						<label
							for="picture"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Profile picture</label
						>
						<div class=" col-sm-5">
							<input
								type="file"
								class="form-control-plaintext py-0"
								id="picture"
								@change="onFileChange"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-edit-input col-sm-4">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="name"
								v-model="teacherData.name"
							/>
						</div>
					</div>
					<div class="form-group row">
						
						<label
							for="gender"
							class="col-sm-3 mt-3 mb-sm-0 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Gender</label
						>
						<div class="custom-edit-input col-sm-3 d-flex">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="teacherData.gender"
								id="gender">
								<option selected disabled value="">Choose</option>
								<option value="male">Male</option>
								<option value="female">Female</option> 
								<option value="other">Other</option>
	
							</select>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="qualification"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Qualification</label
						>
						<div class="custom-edit-input col-sm-3 d-flex" >
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="qualification"
								v-model="teacherData.qualification"
							/>
						</div>
						<label
							for="state"
							class="py-0 col-sm-2 mb-2 mb-sm-0 mt-3 mt-sm-0 text-sm col-form-label font-weight-bold"
							>State</label
						>
						<div class="custom-edit-input col-sm-3">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="state"
								id="state"
							>
								<option selected disabled :value="null">Choose state</option>
								<option v-for="(state,idx) in states" :key="idx" :value="state.id">{{state.state}}</option>
	
							</select>
						</div>
					</div>
					<div class="form-group row">
						
						<label
							for="district"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold pr-6 "
							>District</label
						>
						<div class="custom-edit-input col-sm-3">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="teacherData.district"
								id="district"
							>
								<option selected disabled :value="null">{{choose}}</option>
								<option v-for="(district,idx) in districts" :key="idx" :value="district.id">{{district.district}}</option>			
							</select>
						</div>
						<label
							for="location"
							class="py-0 col-sm-2 mt-3 mt-sm-0 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Locality</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="location"
								v-model="teacherData.location"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="number"
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="teacherData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 mt-3 mt-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="number"
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="teacherData.whatsapp_number"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Subject</label
						>
						<div  class="col-sm-8" >
							<multiselect
								class="custom-multi-select"
								:multiple="true"
								v-model="teacherData.subjects"
								:options="subjectList"
								label="name"
								track-by="name"
                                :hide-selected="true"
                                :close-on-select="true"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div class="custom-edit-input col-sm-5 d-flex">
							<input
								type="text"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="teacherData.meet_url"
							/>
						</div>
					</div>
					
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
	components: {
		Multiselect
	},
	props: ["addOpen", "closeAddModal"],
	data() {
		return {
			isItOpen: false,
			files:[],
			studentList: ["Radha","Davis","Jiya","Brad"],
			state:null,
			subject:[],
			teacherData:{
				name:'',
				mobile:'',
				whatsapp_number:'',
				gender:'',
				district:null,
				location:'',
				meet_url:'',
				qualification:'',
				subjects: [],
				profile_picture:null,
			}
		};
	},
	computed: {
		states(){
			return this.$store.state.LocationData.states;
		},
		districts(){
			return this.$store.state.LocationData.districts;
		},
		choose(){
			return this.state==null ? 'Choose state first':'Choose district'
		},
		isLoaded(){
			return this.$store.state.TmsData.isLoaded;
		},
		subjectList(){
			return this.$store.state.CoursesData.totalSubjectList;
		},
		subjectsCount(){
			return this.$store.state.CoursesData.subjectsCount;
		}
		
	},
	methods: {
		profileSave() {
			var error =undefined;
            if(! this.isLoaded){
				error = "Data is not loaded yet, try again later";
				this.fetchTeachersList();
			}else if(this.teacherData.name.trim()==''){
				error='Teacher name is required';
			}else if(this.teacherData.gender.trim()==''){
				error='Gender is required';
			}else if(this.teacherData.qualification.trim()==''){
				error='Qualification is required';
			}else if(this.teacherData.district==null){
				error='District is required';
			}else if(this.teacherData.location.trim()==''){
				error='Location is required';
			} else if(this.teacherData.mobile==''){
				error='Mobile number is required';
			} else if(this.teacherData.whatsapp_number==''){
				error='Whatsapp number is required';
			} else if(this.teacherData.meet_url.trim()==''){
				error='Enter valid meet link';
			}else if(this.teacherData.meet_url.length>80){
				error='Training link exceeds max limit';
			}	
			if(!this.teacherData.meet_url.includes('https://')){
				this.teacherData.meet_url='https://'+this.teacherData.meet_url;
			}	
			if (error) {
				this.$notify({				
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			let subjectID=this.teacherData.subjects.map(item=>item.id);		
			var formData = new FormData();
			formData.append("profile_picture", this.files[0]);
			formData.append("name", this.teacherData.name);
			formData.append("mobile", this.teacherData.mobile);
			formData.append("whatsapp_number", this.teacherData.whatsapp_number);
			formData.append("gender", this.teacherData.gender);
			formData.append("district", this.teacherData.district);
			formData.append("location", this.teacherData.location);
			formData.append("meet_url", this.teacherData.meet_url);
			formData.append("qualification", this.teacherData.qualification);
			formData.append("subjects", subjectID);
			if(this.files.length==0){
				formData.delete('profile_picture')
			}
			this.$store
				.dispatch("setTeacherDetails", {
					teacherData:formData
				}).then(() =>this.closeAddModal())
				.catch(err => {
					this.$notify({
						title:"Failed to Add teacher",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
			});			
		},
		isItOpenNow() {
			this.isItOpen = !this.isItOpen;
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.teacherData.profile_picture = this.files[0].name;
		},
		fetchStateList() {
			this.$store
				.dispatch("fetchStateList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchDistrictList() {
			this.$store
				.dispatch("fetchDistrictList",this.state)
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchTotalSubjectList() {
			this.$store.dispatch("fetchTotalSubjectList").catch((err) => {
				this.$notify({
					title: "Failed to fetch course list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		
	},
	watch:{
		state(){
			this.fetchDistrictList();
		},
		// subject(){
		// 	let subjectID=this.subject.map(item=>item.id)
		// 	this.teacherData.subjects.push(subjectID);
		// }
	},
	mounted() {
		this.fetchStateList();
		this.fetchTotalSubjectList();
		
		
	},
};
</script>
<style scoped>

.custom-edit-input input {
	
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.modal1 {
	z-index: 1999;
}
.modal2 {
	z-index: 2000;
}
.assessment-text {
	word-wrap: break-word;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}

.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	border-bottom: 1px solid #121619 !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
