<template>
	<div>
		<!-- profileModal -->
		<modal
			class="modal modal1"
			:show="addOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAddModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Course</h3>
				</div>
			</template>
			<div>
				<form><div class="form-group row ">
						<label
							for="picture"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Profile picture</label
						>
						<div class=" col-sm-5">
							<input
								type="file"
								class="form-control-plaintext py-0"
								id="picture"
								@change="onFileChange"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="name"
								v-model="courseData.name"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Available Slots</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="number"
								class="form-control-plaintext py-0"
								id="name"
								v-model="courseData.available_slots"
							/>
						</div>
					</div>

					<!-- <div class="form-group row">
						<label
							for="teacher"
							class="col-sm-3 pt-2 col-form-label py-0 text-sm font-weight-bold"
							>Teachers</label
						>

						<div
							class="custom-edit-input col-sm-8 d-flex align-items-center
							"
						>
							<multiselect
								class="custom-multi-select"
								:multiple="true"
								v-model="courseData.teachers"
								:options="teachersList"
								label="name"
								track-by="name"
                                :hide-selected="true"
                                :close-on-select="false"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>
							<div
								style="
									width: 34px;
									height: 39px;
									border-radius: 95px;
									background: #2194f6;
								"
								class="btn ml-3 rounded-circle d-flex justify-content-center align-items-center"
							>
								<i class="fa fa-plus p-3 text-white" />
							</div>
						</div>
					</div> -->
					<div class="form-group row">
						<label
							for="assesment"
							class="col-sm-3 col-form-label py-0 text-sm font-weight-bold"
							>Description</label
						>
						<div class="col-sm-8 d-flex">
							<textarea
							v-model="courseData.description"
								type="text"
								rows="5"
								class="form-control py-0"
								id="description"
								placeholder="Course description type here"
							></textarea>
						</div>
					</div>
					
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-3 pl-4 pr-4"
					@click.prevent="applyChanges"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
// import Multiselect from "vue-multiselect";
export default {
	components: {
		// Multiselect,
	},
	props: ["addOpen", "closeAddModal",],
	data() {
		return {
			profileEdit: true,
			editLink: true,
			editPayment: true,
			isItOpen: false,
			options: ["English Basic", "Hindi Basic", "Maths Basic","Science Basic"],
			value: [],
			teacherValue: [],
			teacherList: ["Mrs Radha","Mr Davis","Mrs Jiya","Mr Brad"],
			courseData:{
				name:"",
				description:"",
				available_slots:null,
				teachers:[],
				subject_image:null
			},
			files:[]
		};
	},
	computed: {
		Data() {
			return this.$store.state.StmsData.tableData;
		}
	},
	methods: {
		isItOpenNow() {
			this.isItOpen = !this.isItOpen;
		},
		applyChanges() {
			var error = undefined;

			if (this.courseData.name.trim() == "") {
				error = "Course name is required";
			} else if (this.courseData.available_slots == null) {
				error = "Available slots is required";
			} else if (this.courseData.description.trim() == "") {
				error = "Course description is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			var formData=new FormData();
			formData.append("subject_image", this.files[0]);
			formData.append("name", this.courseData.name);
			formData.append("available_slots", this.courseData.available_slots);
			formData.append("description", this.courseData.description);
			if(this.files.length==0){
				formData.delete('subject_image')
			}
			this.$store
				.dispatch("setCourseDetails", {courseData:formData})
				.then(() => {
					// this.$router.back()
					this.closeAddModal()
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to add course",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.courseData.subject_image = this.files[0].name;
		},
	},
	mounted() {},
};
</script>
<style scoped>

.custom-edit-input input {	
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.modal1 {
	z-index: 1999;
}
.modal2 {
	z-index: 2000;
}
.assessment-text {
	word-wrap: break-word;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}

.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	border-bottom: 1px solid #121619 !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
