<template>
	<div>
		<!-- course modal  -->
		<modal
			class="modal modal2 "
			:show="assignCourse"
			@close="assignCourse = !assignCourse"
		>
			<template v-slot:header>
				<h4 class="modal-title">Course Info</h4>
			</template>
			<div class="form-group row d-flex">
				<label
					for="teacher"
					class="col-4 col-sm-4 col-form-label text-sm py-0 font-weight-bold"
					>Teacher</label
				>
				<div v-if="isAssigned" class="col-6 col-sm-6">
					<span>{{ data.courses.teacher }}</span>
				</div>
				<div v-else class="col-8 col-sm-6">
					<select
						class="form-control input-group-alternative pl-0 py-0"
						style="height: 26px"
						id="teacher"
						v-model="assignCourseData.teacher"
					>
						<option selected disabled value="">Select</option>
						<option
							v-for="(teacher, idx) in teacherList"
							:key="idx"
							:value="teacher.id"
						>
							{{ teacher.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group row d-flex">
				<label
					for="subjects"
					class="col-4 col-sm-4 col-form-label text-sm py-0 font-weight-bold"
					>Subjects</label
				>
				<div v-if="isAssigned" class="col-8 col-sm-6">
					<span>{{ data.courses.subject }}</span>
				</div>
				<div v-else class="col-8 col-sm-6">
					<select
						class="form-control input-group-alternative pl-0 py-0"
						style="height: 26px"
						id="subjects"
						v-model="assignCourseData.subject"
					>
						<option selected disabled value="">Select</option>
						<option
							v-for="(subject, idx) in subjects[0]"
							:key="idx"
							:value="subject.id"
						>
							{{ subject.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="form-group row d-flex">
				<label
					for="plan"
					class="col-4 col-sm-4 col-form-label text-sm py-0 font-weight-bold"
					>Course plan</label
				>
				<div v-if="isAssigned" class="col-8 col-sm-6">
					<span>{{ data.courses.course_plan }}</span>
				</div>
				<div v-else class="col-8 col-sm-6">
					<select
						class="form-control input-group-alternative pl-0 py-0"
						style="height: 26px"
						id="plan"
						v-model="assignCourseData.course_plan"
					>
						<option selected disabled value="">Select</option>
						<option
							v-for="(plan, idx) in coursePlan"
							:key="idx"
							:value="plan.id"
						>
							{{ plan.plan_head }}
						</option>
					</select>
				</div>
			</div>
			<template v-slot:footer>
				<base-button
					v-if="!isAssigned"
					@click.prevent="assign"
					size="sm"
					type="primary"
					>Assign course</base-button
				>
			</template>
		</modal>

		<!-- assessment modal -->
		<modal
			class="modal modal2"
			:show="isItOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isItOpenNow"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h2>Assessments</h2>
			</template>
			<div v-if="assessmentCount > 0">
				<div
					v-for="(data, idx) in assessmentData"
					:key="idx"
					class="row py-4"
				>
					<div class="col-12 col-sm-3 d-flex align-items-center pl-5">
						<div
							class="row d-flex align-items-center justify-content-center"
						>
							<img
								src="img/admin.jpg"
								width="40px"
								height="40px"
								alt=""
								class="rounded"
							/>
							<div class="col col-sm">
								<p class="font-weight-bold my-0">
									{{ data.author_name }}
								</p>
								<p class="my-0">
									{{ data.date_added.slice(0, 10) }}
								</p>
							</div>
						</div>
					</div>

					<div v-if="!toggleAssessmentEdit || assessmentNew.id !== data.id" class="col-12 col-sm-8 mt-4 mt-sm-0 assessment-text text-wrap">
						<p class="py-0">{{ data.body }}</p>
					</div>
					<div v-else-if="toggleAssessmentEdit && assessmentNew.id == data.id" class="col-12 col-sm-8 mt-4 mt-sm-0 assessment-text text-wrap">
						<textarea
								type="text"
								rows="6"
								class="form-control"
								id="assesment"
								v-model="assessmentNew.data"
							></textarea>
					</div>

					<div class="d-flex flex-column justif-content-end align-items-start">
						<div v-if="!toggleAssessmentEdit" @click.prevent="assessmentEditFn(data.id)" class="btn btn-sm btn-outline-primary">
							<i class="fa fa-pen"></i>
						</div>
						<div v-if="toggleAssessmentEdit && assessmentNew.id == data.id" @click.prevent="toggleAssessmentEdit = false" class="btn btn-sm btn-outline-danger">
							<i class="fa fa-times"></i>
						</div>
						<div v-if="toggleAssessmentEdit && assessmentNew.id == data.id" @click.prevent="updateAssessment(data.id)" class="btn btn-sm btn-outline-success mt-2">
							<i class="fa fa-save"></i>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<h4
					class="d-flex align-items-center text-muted justify-content-center p-5"
				>
					No Assessment Found
				</h4>
			</div>
		</modal>
		<!-- profileModal -->
		<modal
			class="modal modal1"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeEditModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm text-nowrap">
						Student Profile :
						<span class="text-success"> Regular Class</span>
					</h3>
					<div class="row ml-lg-8 pl-lg-7 mt-3 mt-sm-0 ml-5 ml-sm-0">
						<base-button
							@click.prevent="isItOpen = !isItOpen"
							style="
								border-radius: 20px;
								color: black;
								background-color: #ffff;
								border: 1px solid rgba(158, 152, 155, 0.4);
								box-sizing: border-box;
							"
							class="col pr-3 ml-4 ml-md-0 text-black"
							size="sm"
							icon="fas fa-sticky-note"
							type="primary"
							>Assessment</base-button
						>
						<base-button
							v-if="profileEdit"
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								background-color: #2f80ed;
							"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							>Edit</base-button
						>
						<base-button
							v-else
							@click.prevent="profileEdit = !profileEdit"
							style="border-radius: 20px"
							class="visible py-1 px-3 bg-danger"
							type="danger"
							size="sm"
							>Cancel</base-button
						>
					</div>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-6 col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-6">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="id"
								v-model="data.student_id"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-6 col-sm-3'
									: 'custom-edit-input col-6 col-sm-3'
							"
						>
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="data.name"
							/>
						</div>
						<label
							for="parentName"
							class="col-6 col-sm-3 mt-3 mt-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Guardian</label
						>
						<div class="custom-input col-3 mt-3 mt-sm-0">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="parentName"
								v-model="data.guardian_name"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Student class</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-3'
									: 'custom-edit-input col-3'
							"
						>
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="data.student_class"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-6 col-sm-3 mt-3 mt-sm-0 text-sm col-form-label font-weight-bold"
							>Joined Date</label
						>
						<div class="custom-input col-6 col-sm-3 mt-3 mt-sm-0">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="date"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Address</label
						>
						<div class="'custom-input mt-sm-0 col-6 col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="data.address"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="mobile"
							class="col-6 col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-input col-6 col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="data.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-6 mt-3 mt-sm-0 col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-input col-6 col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext mt-3 mt-sm-0 py-0"
								id="whatsapp"
								v-model="data.whatsapp_number"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="link"
							class="col-6 col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div
							:class="
								editLink
									? 'custom-input mt-3 mt-sm-0 ml-6 ml-sm-0 col-sm-6 d-flex'
									: 'custom-edit-input mt-3 mt-sm-0 ml-6 ml-sm-0 col-sm-6 d-flex'">
							<input
							v-if="data.meet_url !=null&&data.meet_url.length>0 || !editLink"
								type="text"
								:readonly="editLink"
								style="width: 300px"
								class="form-control-plaintext col-6 py-0 text-primary"
								id="link"
								v-model="data.meet_url"
							/>
							<base-button
								class="col-3 ml-0"
								v-if="editLink"
								@click.prevent="updateUrl"
								style="
									background-color: #2f80ed;
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>Update</base-button
							>
							<base-button
								v-else
								class="ml-2 bg-success"
								@click.prevent="editLink = !editLink"
								style="border-radius: 20px"
								type="primary"
								size="sm"
								>Save</base-button
							>
						</div>
					</div>
					<div
						v-if="data.payment_details != null"
						class="form-group row d-flex align-items-center"
					>
						<label
							for="payment"
							class="col-6 col-sm-3 mb-3 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Payment Details</label
						>
						<div class="col-3 col-sm-2 mb-3 mb-sm-0">
							<span>₹{{ data.payment_details.amount }}</span>
						</div>
						<div class="col-3 mb-3 mb-sm-0 col-sm-1 mr-0 mr-sm-3 pl-0">
							<span>{{
								data.payment_details.transaction_type
							}}</span>
						</div>
						<div
							class="row d-flex align-items-center"
						>
							<label
								for="payment"
								class="col-6 col-sm-1 ml-3 ml-sm-2 mr-2 col-form-label text-sm py-0 font-weight-bold"
								>Status:</label
							>
							<span class="col-3 col-sm-6 pl-0 pl-sm-5 pr-0 pr-sm-5">{{
								data.payment_details.status
							}}</span>
							<base-button
								class="col-3 mt-3 mt-sm-0 ml-sm-0 ml-9"
								@click.prevent="paymentUpdate = !paymentUpdate"
								style="
									background-color: #2f80ed;
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>{{data.payment_details.status!='success'?'Update':'View'}}</base-button
							>
						</div>
					</div>
					<div
						v-else
						class="form-group row d-flex align-content-center align-items-center"
					>
						<label
							for="payment"
							class="col-6 col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Payment Details</label
						>
						<span class="col-6 pl-0 pl-sm-3 text-warning"
							>No payment details found</span
						>
					</div>

					<div class="form-group row">
						<label
							for="name"
							class="py-0 pt-2 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Course Details</label
						>
						<div v-if="data.courses.id != null" class="col-6 col-sm-4">
							<button
								@click.prevent="infoScreen"
								class="btn mt-1 text-white btn-sm bg-success text-nowrap"
							>
								{{ data.courses.subject }}
							</button>
						</div>
						<div v-else class="row d-flex">
							<div
								style="
									width: 34px;
									height: 39px;
									border-radius: 95px;
									background: #2194f6;
								"
								@click.prevent="assignCourseBtn"
								class="btn ml-4 rounded-circle d-flex justify-content-center align-items-center"
							>
								<i class="fa fa-plus p-3 text-white" />
							</div>
							<div
								class="col d-flex align-items-center mr-4 pl-0"
							>
								<span
									class="text-primary text-nowrap text-sm font-weight-bold"
									>Assign Course</span
								>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 pt-2 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Teacher</label
						>
						<div v-if="teacherEdit"  class="col-6 col-sm-6 ">
							<button	
							v-if="(data.teacher != null)"		
								@click.prevent=""
								class="btn mt-1 text-white btn-sm bg-success text-nowrap"
							>
								{{ data.teacher }}
							</button>	
							<span v-else class="col-6 pl-0 text-danger">No course selected</span>												
						</div>
						<div v-else class="col-sm-6 custom-edit-input" >
							<select
							@change="onChange"
								class="form-control-plaintext  input-group-alternative py-0 pl-1"
								v-model="data.teacher"
								:disabled="teacherEdit"
								
								style="height:26px"											
							>
							<option selected :value="data.teacher">{{teacherName}}</option>
							<option v-for="teacher in subwiseTeachers" :key="teacher.id" :value="teacher.id">{{teacher.name}}</option>
							</select>
						</div>
						<div v-if="data.teacher !=null" class="col-1 ml--4">
						<base-button
							style="
							border-radius: 20px;
							background-color: #2f80ed;"
							v-if="teacherEdit"
							@click.prevent="teacherEdit =!teacherEdit"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							>Edit</base-button
						><base-button
							style="
							border-radius: 20px;
							background-color: red ;"
							v-else
							@click.prevent="teacherEdit =!teacherEdit"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							>Cancel</base-button
						>
						</div>
						
					</div>

					<div class="form-group row assesment">
						<label
							for="assesment"
							class="col-sm-3 mb-3 mb-sm-0 col-form-label py-0 text-sm font-weight-bold"
							>Assessment</label
						>
						<div class="col-sm-9 d-flex">
							<textarea
								type="text"
								rows="5"
								class="form-control py-0"
								id="assesment"
								placeholder="Assessment details type here"
								v-model="assessData.body"
								:readonly="assessEdit"
							></textarea>
							<div class="ml-3">
								<base-button
									v-if="assessEdit"
									style="
										width: 95px;
										height: 26px;
										border-radius: 20px;
										color: black;
										background-color: #ffff;
										border: 1px solid
											rgba(158, 152, 155, 0.4);
										box-sizing: border-box;
									"
									class="mr-0 text-muted"
									size="sm"
									icon="fas fa-sticky-note  text-muted"
									type="primary"
									@click.prevent="assessEdit = !assessEdit"
									>Edit</base-button
								>
								<div v-else>
									<base-button
										style="
											width: 95px;
											height: 26px;
											border-radius: 20px;
											color: black;
											border: 1px solid
												rgba(158, 152, 155, 0.4);
											box-sizing: border-box;
										"
										class="mr-0 mb-2"
										size="sm"
										type="success"
										@click.prevent="assessmentSave"
										>Save</base-button
									><base-button
										style="
											width: 95px;
											height: 26px;
											border-radius: 20px;
											color: black;
											border: 1px solid
												rgba(245, 7, 7, 0.4);
											box-sizing: border-box;
										"
										class="mr-0"
										size="sm"
										type="danger"
										@click.prevent="
											assessEdit = !assessEdit
										"
										>Cancel</base-button
									>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					v-if="!profileEdit||saveUrl||!teacherEdit"
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave()"
					>Save</base-button
				>
			</template>
		</modal>
		<!-- payment_status Modal -->
		<modal :show="paymentUpdate" @close="paymentUpdate = !paymentUpdate">
			<template v-slot:header>
				<h4 class="modal-title" id="exampleModalLabel">
					{{data.payment_details !=null&&data.payment_details.status!='success'?'Payment Confirmation':'Transaction Details'}}
				</h4>
			</template>
			<div class="form-group row">
				<label
					for="name"
					class="py-0 col-6 col-sm-4 text-sm col-form-label font-weight-bold"
					>Transactin Proof</label
				>
				<div v-if="data.payment_details !=null && data.payment_details.transaction_proof !=undefined"  class="custom-input col-6 col-sm-8">
					<base-button
						type="primary"
						size="sm"
						@click.prevent="showProof"
						>View</base-button
					>
				</div>
				<div v-else class="py-0 pl-3 text-danger">No transaction proof found</div>
			</div>

			<div v-if="data.payment_details !=null&&data.payment_details.status!='success'" class="form-group row">
				<label
					for="name"
					class="py-0 col-6 col-sm-4 text-sm col-form-label font-weight-bold"
					>Payment Status</label
				>
				<div class="'custom-input col-4 col-sm-6">
					<select
						class="form-control input-group-alternative text-dark pl-0 py-0"
						style="height: 26px"
						id="payment"
						v-model="payment_status.status"
					>
						<option selected disabled value="">Choose</option>
						<option value="success">Success</option>
						<option value="pending">Pending</option>
						<option value="failed">Failed</option>
					</select>
				</div>
			</div>
			<template v-slot:footer>
				<base-button
					type="danger"
					size="sm"
					@click.prevent="paymentUpdate = false"
					>Cancel</base-button
				>
				<base-button
				v-if="data.payment_details !=null&&data.payment_details.status!='success'" 
					type="success"
					size="sm"
					@click.prevent="paymentSave"
					>Save</base-button
				>
			</template>
		</modal>
		<div>
			<CoolLightBox :items="images" :index="index" @close="index = null">
			</CoolLightBox>
		</div>
	</div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
	components: { CoolLightBox},
	props: ["isOpen", "closeEditModal", "studentId"],
	data() {
		return {
			toggleAssessmentEdit:false,
			assessmentNew: {
				id:"",
				data:"jgjhjh"
			},
			paymentUpdate: false,
			profileEdit: true,
			assignCourse: false,
			editLink: true,
			assessEdit: true,
			isItOpen: false,
			teacherEdit: true,
			isAssigned: false,
			saveUrl:false,
			teacherID: "",
			data: {
				name: "",
				guardian_name: "",
				gender: "",
				address: "",
				mobile: "",
				meet_url: "",
				whatsapp_number: "",
				date_added: "",
				student_class: "",
				payment_details: "",
				courses: "",
				teacher:''
			},
			assignCourseData: {
				teacher: "",
				subject: "",
				course_plan: "",
			},
			assessData: {
				author_name: null,
				body: "",
			},
			payment_status: {
				status: "",
			},
			images: [	],
			index: null,
			change:false,
			teacherName:''
		};
	},
	computed: {
		isLoaded() {
			return this.$store.state.StmsData.isLoaded;
		},
		assessmentData() {
			return this.$store.state.StmsData.assessmentData;
		},
		assessmentCount() {
			return this.$store.state.StmsData.assessmentCount;
		},
		tableData() {
			return this.$store.state.StmsData.tableData;
		},
		profileData() {
			return this.tableData.find((data) => {
				return data.id === this.studentId;
			});
		},
		teacherList() {
			return this.$store.state.TmsData.tableData;
		},
		subjects() {
			return this.teacherList
				.filter((item) => item.id == this.teacherID)
				.map((subject) => subject.subjects);
		},
		coursePlan() {
			return this.$store.state.StmsData.coursePlan;
		},
		date() {
			return this.data.date_added.slice(0, 10);
		},
		count() {
			return this.$store.state.StmsData.stmsCount;
		},
		teacherCount() {
			return this.$store.state.TmsData.teacherCount;
		},
		subwiseTeachers() {
			return this.$store.state.TmsData.subwiseTecherList;
		},
	},
	methods: {
		profileSave() {
			var error = undefined;
			if (!this.isLoaded) {
				error = "Data is not loaded yet, try again later";
				this.fetchStudentAndCheck();
			} else if (this.data.name == "") {
				error = "username is required";
			} else if (this.data.student_class.trim() == "") {
				error = "Student class is required";
			} else if (this.data.meet_url == "") {
				error = "Training link must be addedd";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setStudentDetails", {
					studentData: this.data,
				})
				.then(() => this.closeEditModal())
				.catch((err) => {
					this.$notify({
						title: "Failed to edit student",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		isItOpenNow() {
			this.isItOpen = !this.isItOpen;
		},
		paymentSave() {
			this.paymentUpdate = !this.paymentUpdate;
			this.setTransaction();
		},
		assignCourseBtn() {
			this.assignCourse = true;
			this.isAssigned = false;
		},
		infoScreen() {
			this.isAssigned = true;
			this.assignCourse = true;
		},
		showProof(){
			if(this.data.payment_details != null &&this.data.payment_details.transaction_proof !=undefined){
				this.images=[];
				this.images.push(this.data.payment_details.transaction_proof);
				this.index=0;
			}			
		},
		assign() {
			var error = undefined;
			if (this.assignCourseData.teacher == "") {
				error = "Teacher must be selected";
			} else if (this.assignCourseData.subject == "") {
				error = "Subject must be selected";
			} else if (this.assignCourseData.course_plan == "") {
				error = "Course plan must be selected";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setCoursePlan", {
					courseData: this.assignCourseData,
					studentID: this.studentId,
				})
				.then(() => this.closeEditModal())
				.catch((err) => {
					this.$notify({
						title: "Failed to add course",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
			this.assignCourse = false;
		},
		assessmentSave() {
			var error = undefined;
			if (this.assessData.body == "") {
				error = " Assessment must be addedd";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setStudentAssessment", {
					studentID: this.studentId,
					assessData: this.assessData,
				})
				.then(() => this.closeEditModal())
				.catch((err) => {
					this.$notify({
						title: "Failed to add assessment",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
			this.assessEdit = !this.assessEdit;
		},
		assessmentEditFn(assessmentID) {
			this.toggleAssessmentEdit = true
			this.assessmentNew.id = assessmentID
			this.assessmentNew.data = this.assessmentData.find(item=>item.id == assessmentID).body
		},
		updateAssessment(assessmentID) {
			var error = undefined;
			if (this.assessmentNew.data == "") {
				error = " Assessment must not be empty";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			let obj = {
				body:this.assessmentNew.data
			}
			this.$store
				.dispatch("updateStudentAssessment", {
					studentID: this.studentId,
					assessmentID:assessmentID,
					assessData: obj,
				})
				.then(() => {
					this.fetchStudentAssessment()
					this.toggleAssessmentEdit = false
					})
				.catch((err) => {
					this.$notify({
						title: "Failed to update assessment",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchStudentAndCheck() {
			this.$store
				.dispatch("fetchSTMSList", {
					searchTerm: "",
					itemsPerPage: this.count,
					startIndex: "",
				})
				.then(() => {
					this.checkStudentDetails();
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch student data for verification",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		checkStudentDetails() {
			if (this.studentId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid Student ID " + this.studentId,
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch("fetchStudentDetails", this.studentId)
							.then(() => {
								this.prepareStudent();
							})
							.catch((err) => {
								this.$notify({
									title: "Failed to fetch student details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString(),
								});
							});
					} else {
						this.prepareStudent();
					}
				}
			}
		},
		prepareStudent() {
			this.data = JSON.parse(JSON.stringify(this.profileData));
		},
		fetchStudentAssessment() {
			this.$store
				.dispatch("fetchStudentAssessment", {studentID:this.studentId})
				.then(()=>{
					console.log("assessmentData",this.assessmentData)
					}).catch((err) => {
					this.$notify({
						title: "Failed to fetch assessment",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchTeachersList() {
			this.$store
				.dispatch("fetchTeachersList", {searchTerm: "",itemsPerPage: this.teacherCount,startIndex: "",})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch Teachers",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchCoursePlan() {
			this.$store.dispatch("fetchCoursePlan").catch((err) => {
				this.$notify({
					title: "Failed to fetch course plan",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		setTransaction() {
			this.$store
				.dispatch("setTransaction", {
					transactionID: this.data.payment_details.id,
					data: this.payment_status,
				})
				.then(() => {
					this.closeEditModal();
					this.$store.dispatch('fetchNotificationsList');
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to edit payment",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		updateUrl(){
			this.editLink = !this.editLink;
			this.saveUrl=true;
		},
		fetchSubwiseTeachersList(){
			this.$store.dispatch("fetchSubwiseTeachersList",this.data.courses.subject_id).catch((err) => {
				this.$notify({
					title: "Failed to fetch teacher",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		onChange(){
			this.change=true;
		}
	},
	watch: {
		"assignCourseData.teacher"() {
			this.teacherID = this.assignCourseData.teacher;
		},
		teacherEdit(){
			if(this.teacherEdit==false)
			this.fetchSubwiseTeachersList();

		},
		"data.teacher"(){
			if(this.change)
			this.teacherName=this.subwiseTeachers.find(item=>item.id==this.data.teacher).name;
			else this.teacherName=this.data.teacher;

		}
	},
	mounted() {
		if (!this.isLoaded) {
			this.fetchStudentAndCheck();
		} else {
			this.checkStudentDetails();
		}
		if (this.studentId != undefined) {
			this.fetchStudentAssessment();
		}
								
		this.fetchTeachersList();
		this.fetchCoursePlan();
	},
};
</script>
<style scoped>
.custom-input input {
	width: 250px !important;
	height: 21px;
	outline: none;
}
.custom-add-input input {
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input select{
	border: none;
    outline: none;
	box-shadow: none;
    
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input input:focus {
	outline: none;
	border: none;
} 
.modal2 {
	z-index: 2999;
}

.assessment-text {
	word-wrap: break-word;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}
</style>
