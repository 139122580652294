<template>
	<div>
		<!-- profileModal -->
		<modal
		class="modal"
			:show="addOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAddModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Student</h3>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row ">
						<label
							for="picture"
							class="py-0 mb-2 mb-sm-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Profile picture</label
						>
						<div class=" col-sm-5">
							<input
								type="file"
								class="form-control-plaintext py-0"
								id="picture"
								@change="onFileChange"
							/>
						</div>
					</div>
					<div class="form-group row ">
						<label
							for="name"
							class="py-0 mb-2 mb-sm-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="name"
								v-model="studentData.name"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="parent"
							class="py-0 mb-2 mb-sm-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Parent name</label
						>
						<div class="custom-edit-input col-10 col-sm-3 pl-1">
							<select
							v-if="isChanged"							
								class="form-control input-group-alternative py-0 text-dark "
								style="height: 26px"
								:value="parentID"
								id="parent"
							>
								<option :selected="true" :value="parentID">{{parentName}}</option>
	
							</select>
							<select	
							v-else					
								class="form-control input-group-alternative py-0 text-dark "
								style="height: 26px"
								v-model="studentData.user"
								id="parent"
							>
								<option selected disabled :value="null">Select</option>
								<option v-for="(parent,idx) in parentData" :key="idx"   :value="parent.parent_id">{{parent.name}}</option>

							</select>
							
						</div>
						<div v-if="isChanged" class="d-flex ml-3 ml-sm-0 justify-content-center">
							<i @click.prevent="" class="btn btn-sm fa fa-check pl-1 pt-1  text-primary" />
						</div>
						<div v-else class="d-flex justify-content-center">
							<i @click.prevent="addParentOpen=true" class="btn btn-sm fa fa-plus pl-1 pt-1  text-primary" />
						</div>
						<label
							for="gender"
							class="col-sm-2 mt-3 mt-sm-0 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Gender</label
						>
						<div class="custom-edit-input col-sm-3 pl-1 d-flex">
							<select							
								class="form-control input-group-alternative py-0 text-dark"
								style="height: 26px"
								v-model="studentData.gender"
								id="gender">
								<option selected disabled value="">Choose</option>
								<option value="male">Male</option>
								<option value="female">Female</option> 
	
							</select>
						</div>
					</div>
								
					<div class="form-group row">
						<label
							for="age"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Age</label
						>
						<div class="custom-edit-input col-sm-3 d-flex">
							<input
								type="text"
								class="form-control-plaintext py-0 "
								id="age"
								v-model="studentData.age"
							/>
						</div>
						<label
							for="class"
							class="col-12 col-sm-2 ml-0 ml-sm-4 mt-3 mt-sm-0 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Class</label
						>
						<div class="custom-edit-input col-sm-3 d-flex">
							<input
								type="text"
								class="form-control-plaintext py-0 "
								id="class"
								v-model="studentData.student_class"
							/>
						</div>
					</div>					
					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div class="custom-edit-input col-sm-6 d-flex">
							<input
								type="text"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="studentData.meet_url"
							/>
						</div>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
		<AddParentModal
		v-if="addParentOpen"
		:addParentOpen="addParentOpen"
		:closeAddParentModal="closeAddParentModal"
		:test-fn="testFn"
		/>
	</div>
</template>
<script>
// import Multiselect from "vue-multiselect";
import AddParentModal from "./AddParent";
export default {
	components: {
		AddParentModal

	},
	props: ["addOpen", "closeAddModal"],
	data() {
		return {
			files:[],
			isItOpen: false,
			addParentOpen:false,
			isParent:false,
			options: ["English Basic", "Hindi Basic", "Maths Basic","Science Basic"],
			value: [],
			teacherValue: [],
			teacherList: ["Mrs Radha","Mr Davis","Mrs Jiya","Mr Brad"],
			parentName:'',
			parentID:null,
			isChanged:null,
			formData:"",
			studentData:{
				name:'',
				user:null,
				gender:'',
				meet_url:'',
				age:'',
				student_class:'',
				profile_picture:null
			},

		};
	},
	computed: {
		choose(){
			return this.state==null ? 'Choose state first':'Choose district'
		},
		isLoaded(){
			return this.$store.state.StmsData.isLoaded;
		},
		parentData() {
			return this.$store.state.ParentData.parent_tableData;
		},
		parent_data() {
			return this.$store.state.ParentData.parent_data;
		},
		count(){
			return this.$store.state.ParentData.parentCount;
		},
		
	},
	methods: {
		profileSave() {
			if(this.isChanged==true){
				this.studentData.user=this.parentID;
			}
			console.log(this.studentData.user)
			var error =undefined;
			if(this.studentData.name.trim()==''){
				error='Name is required';
			}else if(this.studentData.user==null){
				error='User must be selected';
			}else if(this.studentData.gender.trim()==''){
				error='Gender is required';
			}else if(this.studentData.age==''){
				error='Student age is required';
			}else if(this.studentData.student_class==''){
				error=' Student class is required';
			} else if(this.studentData.meet_url==''){
				error='Training link is required';
			}else if(this.studentData.meet_url.length>25){
				error='Training link exceeds max limit';
			}else if(!this.studentData.meet_url.includes('meet')){
				error='Enter valid Training link ';
			}
			if (error) {
				this.$notify({				
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			console.log(this.studentData)
			this.formData = new FormData();
			this.formData.append("profile_picture", this.files[0]);
			this.formData.append("name", this.studentData.name);
			this.formData.append("user", this.studentData.user);
			this.formData.append("age", this.studentData.age);
			this.formData.append("gender", this.studentData.gender);
			this.formData.append("student_class", this.studentData.student_class);
			this.formData.append("meet_url", this.studentData.meet_url);
			if(this.files.length==0){
				this.formData.delete('profile_picture')
			}
			this.$store
				.dispatch("setStudentDetails", {
					studentData:this.formData
				})
				.then(() => this.closeAddModal())
				.catch(err => {
					this.$notify({
						title:"Failed to add student",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
			});
		},	
		isItOpenNow() {
			this.isItOpen = !this.isItOpen;
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.studentData.profile_picture = this.files[0].name;
		},
		closeAddParentModal() {
			this.addParentOpen = !this.addParentOpen;
			this.fetchParentList();
			this.isParent=true;
		},
		testFn(data){
			console.log(data)
		},
		fetchParentList() {
			this.$store
				.dispatch("fetchParentList",{itemsPerPage:this.count,startIndex:''})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch users",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},

	},
	watch:{
		'parent_data.name'(){
			console.log(this.parent_data)
			this.parentName=this.parent_data.name;
			this.parentID=this.parent_data.id;
			this.isChanged=this.parent_data.status;
			console.log(this.parent_data)

		}
	},
	
	mounted() {	
		this.fetchParentList();
		
	},
};
</script>
<style scoped>
.custom-edit-input input {
	
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.modal1 {
	z-index: 1999;
}
.modal2 {
	z-index: 2000;
}
.assessment-text {
	word-wrap: break-word;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}

.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	border-bottom: 1px solid #121619;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
