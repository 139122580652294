<template>
    <div>
        <modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isModalOpen"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="col-sm-6 pl-1 ">
					Student Profile : <span class="text-warning">Counseling</span> 
					
				</h3>
				<div v-if="currentStatus=='False'" class="Btn ml-lg-8 pl-lg-8">
					<base-button
					v-if="profileEdit"
						@click.prevent="profileEdit = !profileEdit"
						style="border-radius: 20px; background-color: #2f80ed"
						class="hidden py-1 pl-4 pr-4"
						type="primary"
						size="sm"
						>Edit</base-button
					>
					<base-button v-else
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								
							"
							class="visible  py-1 px-3 bg-danger"
							type="danger"
							size="sm"
							
							>Cancel</base-button
						>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-sm-9">
							<input
								type="text"
								readonly
								class=" form-control-plaintext py-0"
								id="id"
								:value="data.id==undefined?data.id: data.id.slice(0,3)"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class= 'custom-input col-sm-3 ' >
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="data.name"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class= 'custom-input col-sm-3 '>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="profileData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold mr-0"
							>Whatsapp Number</label
						>
						<div class='custom-input col-sm-3 '>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="profileData.whatsapp_number"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="date"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Scheduled Date</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '">
							<input
								type="date"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="date"
								v-model="data.date"
							/>
						</div>
						<label
							for="time"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Time</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '">
							<input
								type="time"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="time"
								v-model="data.time"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Couseling Link</label
						>
						<div :class="edit ? 'custom-input col-sm-4 d-flex ':'custom-edit-input col-sm-4 d-flex '">
							<input
							v-if="data.meet_url !=null && data.meet_url.length>0||!edit"
								type="text"
								:readonly="edit"
								style="width: 300px"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="data.meet_url"
							/>
							<div v-if="currentStatus=='False'" class="col-sm-2 pl-0">
							<base-button
							v-if="edit"
								class="ml-0"
								@click="updateUrl"
								style="
									background-color: #2f80ed;
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>Update</base-button
							>
							<base-button
							v-else
								class="ml-0 bg-success"
								@click="edit = !edit"
								style="
									
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>Save</base-button
							>
						</div>
						</div>
					</div>					

					
				</form>
			</div>
			<template v-if="currentStatus=='False'&&(!profileEdit||linkEdit)" v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
    </div>
</template>
<script>

export default {
    props:['isOpen','isModalOpen','studentId','currentStatus'],
    data(){
        return{
        profileEdit:true,
		edit:true ,
		linkEdit:false,
		counselorValue: ["Mrs.Ramya"],
		counselorList: ["MrsRamya","Mr.Kiran","Mrs.Luffy","Mr.Ace"] ,
		data:{
				name:'',
				mobile:'',
				meet_url:'',
				whatsapp_number:'',
				date:'',
				time:'',
				admin_approval:true

			},
		outputData: {
			date:'',
			time:'',
			meet_url:'',
			admin_approval:false
		}  
        }
    },
    computed:{
		tableData() {
			return this.$store.state.CounsellersData.tableData;
		},
		profileData(){
			return this.tableData.find(data=>{
				return 	data.id===this.studentId;
			});
		},
		isLoaded(){
			return this.$store.state.CounsellersData.isLoaded;
		}
	},
	methods:{
		profileSave() {
			var error =undefined;
			if(! this.isLoaded){
				error = "Data is not loaded yet, try again later";
				this.fetchCouselingListAndCheck();
			}else if(this.data.date==''){
				error='Counseling date is required';
			}else if(this.data.time==''){
				error='Counseling time is required';
			}else if(this.data.meet_url=='' || this.data.meet_url==null){
				error='Counseling url is required';
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.outputData.date = this.data.date
			this.outputData.time = this.data.time
			this.outputData.meet_url = this.data.meet_url
			this.outputData.admin_approval = true
			this.outputData.id = this.data.id

			console.log("outputData here",this.outputData)
			this.$store
				.dispatch("setCounselingDetails", {
					counselingData:this.outputData
				})
				.then(() =>{
				this.isModalOpen();
				this.$store.dispatch('fetchNotificationsList');})
				.catch(err => {
					this.$notify({
						title:
							"Failed to edit student",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
					
		},
		fetchCouselingListAndCheck() {
			this.$store
				.dispatch("fetchConselingList",{ status:'',itemsPerPage:'',startIndex:''})
				.then(() => {
					this.checkCouselingDetails();
				})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch student data for verification",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		checkCouselingDetails() {
			if (this.studentId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid Student ID " + this.studentId
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch("fetchConselingDetails", this.studentId)
							.then(() => {
								this.prepareStudent();
							})
							.catch(err => {
								this.$notify({
									title: "Failed to fetch couseling details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString()
								});
							});
					} else {
						this.prepareStudent();
					}
				}
			}
		},
		prepareStudent() {
			this.data = JSON.parse(JSON.stringify(this.profileData));
		},
		updateUrl(){
			this.edit = !this.edit;
			this.linkEdit=true;
		}
	},
    mounted() {	
		if (!this.isLoaded) {
			this.fetchCouselingListAndCheck();
		} else {
			this.checkCouselingDetails();			
		}	
	},
}
</script>
<style scoped>
.custom-input input{
    width: 250px !important;
	height: 21px;
	outline: none;
	
}
.custom-edit-input input{

	border: none;
    border-bottom: 1px solid #121619;
    color: black;
	transition: .3s all linear
    
}
.custom-edit-input input:focus{
	outline: none;
}
.visible {
  visibility: visible;
  transition: opacity 2s linear;
}
.hidden {
  transition: visibility 0s 2s, opacity 2s linear;
}
.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>