<template>
	<div>
		<modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isModalOpen"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="col-6 pl-0 mt-2">
					Teacher Profile
				</h3>
				<div class="Btn ml-lg-8 pl-lg-8 ml-5">
					<base-button v-if="profileEdit"
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								background-color: #2f80ed;
							"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							
							>Edit</base-button
						>
					<base-button v-else
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								
							"
							class="visible py-1 px-3 bg-danger"
							type="danger"
							size="sm"
							
							>Cancel</base-button
						>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-6 col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-6 col-sm-9">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="id"
								v-model="profileID"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div :class="profileEdit ? 'custom-input col-6 col-sm-3 ':'custom-edit-input col-6 col-sm-3 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="teacherData.name"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="qualification"
							class="py-0 col-6 col-sm-3 mb-3 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Qualification</label
						>
						<div class="custom-input col-6 col-sm-3" >
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="qualification"
								v-model="teacherData.qualification"
							/>
						</div>
						
						<label
							for="name"
							class="py-0 col-6 col-sm-2 text-sm col-form-label font-weight-bold"
							>Joined Date</label
						>
						<div class="custom-input col-6 col-sm-3">
						<span>{{teacherData.id !==undefined? teacherData.date_added.slice(0,10):teacherData.date_added}}</span>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 mb-3 mb-sm-0 text-sm col-form-label font-weight-bold"
							>State</label
						>
						<div :class="profileEdit ? 'custom-edit-input mb-3 mb-sm-0 col-6 col-sm-3 pl-2':'custom-input col-6 mb-3 mb-sm-0 col-sm-3 pl-2'">	
							<!-- <span class="pl-1 ml-1 text-sm" v-if="profileEdit">{{states.find(state=>state.id==teacherData.state)[0].state}}</span> -->
							<select							
								class="form-control-plaintext input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="teacherData.state"
								:disabled="profileEdit"
								id="state">
								<option v-for="(state,idx) in states" :key="idx" :value="state.id">{{state.state}}</option>
							</select>
						</div>
						<label
							for="district"
							class="py-0 col-6 col-sm-2 text-sm col-form-label font-weight-bold"
							>District</label
						>
						<div class="custom-input col-6 col-sm-3">
							<input
							v-if="profileEdit"
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="district"
								v-model="teacherData.district_name"
							/>
							<select	
							v-else						
								class="form-control-plaintext input-group-alternative py-0 pl-2 text-dark"
								style="height: 26px"
								id="district"
								@change="onChange($event)"
								>
								<option :selected="true" :value="teacherData.district">{{teacherData.district_name}}</option>
								<option v-for="(district,idx) in districts" :key="idx" :value="district.id">{{district.district}}</option>	
							</select>
						</div>
						
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 text-sm col-form-label font-weight-bold"
							>Location</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-6 col-sm-3'
									: 'custom-edit-input col-6 col-sm-3'
							"
						>
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="teacherData.location"
							/>
						</div>
						
						
					</div>
					<div class="form-group row">
						<label
							for="mobile"
							class="col-6 col-sm-3 mb-3 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div :class="profileEdit ? 'custom-input col-6 col-sm-3 ':'custom-edit-input col-6 col-sm-3 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="teacherData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-6 col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div :class="profileEdit ? 'custom-input col-6 col-sm-3 ':'custom-edit-input col-6 col-sm-3 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="teacherData.whatsapp_number"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="link"
							class="col-6 col-sm-3 mb-3 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div :class="edit ? 'custom-input col-12 col-sm-4 d-flex ':'custom-edit-input col-12 col-sm-4 d-flex '">
							<input
							v-if="teacherData.meet_url !=null&&teacherData.meet_url.length>0||!edit"
								type="text"
								:readonly="edit"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="teacherData.meet_url"
							/>
							<div class="col-sm-2">
							<base-button
							v-if="edit"
								class=""
								@click="updateUrl"
								style="
									background-color: #2f80ed;
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>Update</base-button
							>
							<base-button
							v-else
								class="ml-2 bg-success"
								@click="edit = !edit"
								style="
									
									border-radius: 20px;
								"
								type="primary"
								size="sm"
								>Save</base-button
							>
						</div>
						</div>
					</div>

					<div class="form-group row mt-4 mt-sm-0">
						<label
							for="name"
							class="py-0 col-6 col-sm-3 pt-2 text-sm col-form-label font-weight-bold"
							>Subjects</label
						>
						<div v-if="profileEdit"  class="col-8 col-sm-8 subject" >
							<button  v-for="(subject,idx) in teacherData.subjects" :key="idx"  @click.prevent="" class="btn mb-2 text-white btn-sm bg-success text-wrap">
									{{subject.name}}
							</button>
							<div v-if="teacherData.subjects.length==0" class="row">
								<div :style="assignButtonStyle"
									class="btn col-1 ml-3 rounded-circle d-flex justify-content-center align-items-center"
									@click.prevent="profileEdit=false"
									><i class="fa fa-plus text-white"/>
								</div>
								<div class="d-flex align-items-center ml-1">
									<span class="text-primary font-weight-bold">Select subjects</span>
								</div>
							</div>
							
						</div>
						<div v-else class="col-sm-8" >
							<multiselect
								class="custom-multi-select"
								:multiple="true"
								v-model="teacherData.subjects"
								:options="subjectList"
								label="name"
								track-by="name"
                                :hide-selected="true"
                                :close-on-select="false"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>
						</div>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
				v-if="!profileEdit||saveUrl"
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
	components: {
		Multiselect,
	},
props:['isOpen','isModalOpen',"studentId"],
    data(){
        return{
            profileEdit:true,
			edit: true,
			saveUrl:false,
			teacherData:{
				name:'',
				mobile:'',
				whatsapp_number:'',
				district:null,
				district_name:'',
				state:null,
				location:'',
				meet_url:'',
				qualification:'',
				subjects: [],
			}
        }
    },
    computed:{
		tableData() {
			return this.$store.state.TmsData.tableData;
		},
		
		
		districts(){
			return this.$store.state.LocationData.districts;
		},
		isStateLoaded(){
			return this.$store.state.LocationData.isLoaded;
		},
		states(){
			return this.$store.state.LocationData.states;
		},
		subjectList(){
			return this.$store.state.CoursesData.totalSubjectList;
		},
		subject(){
			return this.subjectList.map(item=>item.name)
		},
		profileData() {
			return this.tableData.find((data) => {
				return data.id === this.studentId;
			});
		},
		profileID(){
			return   this.profileData.id.slice(0,4);
		},
		profileDate(){
			return  this.profileData.date_added;
		},
		isLoaded(){
			return this.$store.state.TmsData.isLoaded;
		},
		assignButtonStyle() {
			return {
				height: "39px",
				width: "35px",
				background: "#2194f6",
			};
		},
		teacherCount() {
			return this.$store.state.TmsData.teacherCount;
		},
		subjectsCount(){
			return this.$store.state.CoursesData.subjectsCount;
		}
		
	},
	methods:{
		profileSave() {
			var error =undefined;
            if(! this.isLoaded){
				error = "Data is not loaded yet, try again later";
				this.fetchTeachersAndCheck();
			}else if(this.teacherData.name.trim()==''){
				error='Teacher name is required';
			}else if(this.teacherData.district==null){
				error='District is required';
			}else if(this.teacherData.location.trim()==''){
				error='Location is required';
			} else if(this.teacherData.mobile==''){
				error='Mobile number is required';
			} else if (!this.teacherData.mobile.length>12) {
				error = 'Mobile number should be of the format <10 digits> ';
			}  else if(this.teacherData.whatsapp_number==''){
				error='Whatsapp number is required';
			} else if (!this.teacherData.whatsapp_number.length>12) {
				error = 'Mobile number should be of the format <10 digits> ';
			} 
			if(!this.teacherData.meet_url.includes('https://')){
				this.teacherData.meet_url='https://'+this.teacherData.meet_url;
			}
			console.log(this.teacherData.meet_url)
			if (error) {
				this.$notify({				
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}

			this.$store
				.dispatch("setTeacherDetails", {
					teacherData:this.teacherData
				})
				.then(() =>this.isModalOpen())
				.catch(err => {
					this.$notify({
						title:
							"Failed to Edit teacher",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
			});			
		},
		fetchTeachersAndCheck() {
			this.$store
				.dispatch("fetchTeachersList",{searchTerm:'',itemsPerPage:this.teacherCount,startIndex:''})
				.then(() => {
					this.checkTeacherDetails();
				})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch teacher data for verification",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		checkTeacherDetails() {
			if (this.studentId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid Teacher ID " + this.studentId
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch("fetchTeacherDetails", this.studentId)
							.then(() => {
								this.prepareStudent();
							})
							.catch(err => {
								this.$notify({
									title: "Failed to fetch teacher details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString()
								});
							});
					} else {
						this.prepareStudent();
					}
				}
			}
		},
		onChange(event){
			this.teacherData.district=event.target.value;

		},
		prepareStudent() {
			this.teacherData = JSON.parse(JSON.stringify(this.profileData));
		},
		fetchDistrictList() {
			this.$store
				.dispatch("fetchDistrictList",this.teacherData.state)
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchStateList() {
			this.$store
				.dispatch("fetchStateList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchTotalSubjectList() {
			this.$store.dispatch("fetchTotalSubjectList").catch((err) => {
				this.$notify({
					title: "Failed to fetch course list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		updateUrl(){
			this.edit= !this.edit;
			this.saveUrl=true;
		}

	},
	
	watch:{
		'teacherData.state'(){
			this.fetchDistrictList();		
		}		
	},
    mounted() {
		this.fetchStateList();
		if (!this.isLoaded) {
			this.fetchTeachersAndCheck();
		} else {
			this.checkTeacherDetails();						
		} 		
		this.fetchTotalSubjectList();
		console.log(this.subjectsCount)
	},
}
</script>
<style scoped>
.custom-input input{
    width: 250px !important;
	height: 21px;
	outline: none;
	
}
.custom-input select{
	outline: none;
}
.custom-edit-input input{

	border: none;
    border-bottom: 1px solid #121619;
    color: black;
	transition: .3s all linear
    
}
.custom-edit-input select{
	border: none;
    outline: none;
	box-shadow: none;
    
}
.custom-edit-input input:focus{
	outline: none;
	}
.visible {
  visibility: visible;
  transition: opacity 2s linear;
}
.subject{
	height: 140px;
	overflow-x: scroll;
	
}
.hidden {
  transition: visibility 0s 2s, opacity 2s linear;
}
.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	margin-bottom: 3px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}


</style>