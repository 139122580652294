<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div  class="col-sm-12 head-section">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									Interview Completed
								</h2>
								<!-- <div class="row d-flex justify-content-between">
									<form class="col-12 col-sm-7">
										<div class="form-row mt-4">
											<div
												class="search col-12 mt-2 mt-sm-0 col-sm-5"
											>
												<base-input
													placeholder="Search"
													class="input-group-alternative"
													addon-right-icon="fas fa-search"
												>
												</base-input>
											</div>
											<div class="col-12 col-sm-3">
												<base-button
													type="primary"
													class="col-12"
													outline
													>Search</base-button
												>
											</div>
										</div>
									</form>
									<div
										class="col-12 col-sm-4 d-flex justify-content-end mt-5 mt-sm-0 align-items-center"
									>
										<base-button
											@click.prevent=""
											type="primary"
											outline
											class="col-12 col-sm-6"
											
											>Add New +</base-button
										>
									</div>
								</div> -->
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div v-if="tableData.length >0" class="table-responsive">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template v-if="tableData.length >0" slot-scope="{ row, index }">
										<td class="pl-0">
											{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-lg-2">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-2 mr-4">
													{{ row.id && row.id.slice(0,3) }}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex align-items-center"
											>
												<div class="col-1">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9 ml-1">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}</span
														>
													</div>
													<div class="col-12">
														<span>{{row.qualification}}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex mr-2 align-items-center"
											>
												{{ row.mobile }}
											</div>
										</td>
                                        <td class="col-4">
											<div
												class="row d-flex mr-2 align-items-center"
											>
												{{ row.address}}
											</div>
										</td>
									</template>
								</base-table>
								
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
								</base-table>
							<div  class="col-12 d-flex flex-column justify-content-center align-items-center">
									<img src="img/undraw.png" height="250px" width="250px" alt="No data found">
									<h3 class="text-muted mr-4">Oops! No data found</h3>
							</div>
								
						</div>
							<base-pagination
							v-if="tableData.length>0"
								class="mt-5"
								:perPage="itemsPerPage"
								:total="interviewCount"
								v-model="currentPage"
								align="center"
							/>
						</card>
						<div
							class="row mt-4 d-flex justify-content-around"
						>
							<div v-for="(data, index) in cardData" :key="index"
								class="card-sm custom-card rounded shadow bg-white py-3 my-3 my-sm-0 py-sm-3 col-sm mx-2 col-12"
							>
								<div
									:style="
										data.color === 'red'
											? 'background-color:#9EBF6D;'
											: data.color === 'blue' ? 'background-color:#EB5757;' : 'background-color:#F46399'
									"
									:class="
										data.color === 'red'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm text-nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<div
										@click.prevent="redirectTo(data.color)"
										class="btn col-2"
									>
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="5"
						:currentPage="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
       <template>
			<div>
				<modal
					class="modal"
					:show="isOpen"
					footerClasses="pt-0 pb-1"
					headerClasses="	"
					@close="isModalOpen"
					modal-classes=" modal-dialog-centered  modal-lg   "
					type="mini"
				>
					<template v-slot:header>
						<h3  class="col-6">Teacher Profile</h3>
						<!-- <div class="Btn ml-lg-8 pl-lg-8">
							<base-button
								v-if="profileEdit"
								@click.prevent="profileEdit = !profileEdit"
								style="
									border-radius: 20px;
									background-color: #2f80ed;
								"
								class="hidden py-1 pl-4 pr-4"
								type="primary"
								size="sm"
								>Edit</base-button
							>
							<base-button
								v-else
								@click.prevent="profileEdit = !profileEdit"
								style="border-radius: 20px"
								class="visible py-1 px-3 bg-danger"
								type="danger"
								size="sm"
								>Cancel</base-button
							>
						</div> -->
					</template>
					<div>
						<form>
							<div class="form-group row">
								<label
									for="id"
									class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
									>ID</label
								>
								<div class="custom-input col-sm-9">
									<span class="py-0 text-sm">{{profileData.id && profileData.id.slice(0,3)}}</span>
								</div>
							</div>
							<div class="form-group row">
								<label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Name</label
								>
								<div
									class="custom-input col-sm-3"
								>
									<input
										type="text"
										readonly
										class="form-control-plaintext py-0"
										id="name"
										v-model="loadedData.name"
									/>
								</div>
                                <label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Qualification</label
								>
								<div
									class="custom-input col-sm-3"
								>
									<input
										type="text"
										readonly
										class="form-control-plaintext py-0"
										id="name"
										v-model="loadedData.qualification"
									/>
								</div>
							</div>

                            <div class="form-group row">
								<label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Mobile</label
								>
								<div
									class="custom-input col-sm-3"
								>
									<input
										type="text"
										readonly
										class="form-control-plaintext py-0"
										id="name"
										v-model="loadedData.mobile"
									/>
								</div>
                                <label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Whatsapp Number</label
								>
								<div
									class="custom-input col-sm-3"
								>
									<input
										type="text"
										readonly
										class="form-control-plaintext py-0"
										id="name"
										v-model="loadedData.whatsapp_number"
									/>
								</div>
							</div>

                            <div class="form-group row">
								<label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Address</label
								>
								<div
									class="custom-input col-sm-6"
								>
									<input
										type="text"
										readonly
										class="form-control-plaintext py-0"
										id="name"
										v-model="loadedData.address"
									/>
								</div>
							</div>				
							<div class="form-group row">
						<label
							for="link"
							class="col-6 col-sm-3 mb-3 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Meet URL</label
						>
						<div class='custom-edit-input  col-12 col-sm-4 d-flex'>
							<input
								type="text"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="applicantData.meet_url"
							/>
						</div>
					</div>
							<div  class="form-group row">
						<label 
							for="teacher"
							class="col-sm-3 pt-2 col-form-label py-0 text-sm font-weight-bold"
							>Subjects</label
						>
						<div v-if="subject && applicantData.subjects.length == 0"  class="col-7 col-sm-7">
							<button  v-for="(sub ,idx) in loadedData.subjects" :key="idx" @click.prevent="" class="btn mb-2 text-white btn-sm bg-success text-wrap">
								{{courseList.find(item=>item.id==sub).name}}
								</button>
						</div>
						<div v-else-if="subject && applicantData.subjects.length > 0"  class="col-7 col-sm-7">
							<button  v-for="(sub ,idx) in applicantData.subjects" :key="idx" @click.prevent="" class="btn mb-2 text-white btn-sm bg-success text-wrap">
								{{sub.name}}
								</button>
						</div>
						<div v-else class=" col-sm-6">			
							<multiselect
								class="custom-multi-select"
								:multiple="true"
								v-model="applicantData.subjects"
								:options="courseList"
								label="name"
								track-by="name"
                                :hide-selected="true"
                                :close-on-select="false"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>					
						</div>
						<div class="col-1">
						<base-button
							style="
							border-radius: 20px;
							background-color: #2f80ed;"
							v-if="subject"
							@click.prevent="subject =!subject"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							>Edit</base-button
						><base-button
							style="
							border-radius: 20px;
							background-color: red ;"
							v-else
							@click.prevent="subject =!subject"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							>Cancel</base-button
						>
						</div>
					</div>
						</form>
					</div>
					<template v-slot:footer>
						<base-button
							type="primary pl-4 pr-4"
							style="
								width: 180px;
								height: 46px;
								border-radius: 20px;
								background-color: #f35d94;
							"
							class="mb-2"
							@click.prevent="rejectChanges"
							>Reject</base-button
						>
                        <base-button
							type="primary pl-4 pr-4"
							style="
								width: 180px;
								height: 46px;
								border-radius: 20px;
								background-color: #24A46D;
							"
							class="mb-2"
							@click.prevent="applyChanges"
							>Accept</base-button
						>
					</template>
				</modal>
			</div>
		</template>

	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Notification from '../../components/Notification.vue';
// import NotificationPanel from '@/layout/NotificationPanel.vue';
export default {
	name: "InterviewCompleted",
	components: {
		Multiselect,Notification
	},

	data() {
		return {
			isOpen: false,			
			itemsPerPage: 15,
			startIndex:0,
			currentPage: 1,
			applicantID: null,
			profileEdit: true,
			loadedData: {},
			applicantData:{
				id:"",
				subjects: [],
				meet_url:'',
				is_verified:false,
				is_rejected:false
			},
            edit:false,
			subject:true,
		};
	},
	computed: {
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		tableHeader() {
			return this.$store.state.InterviewCompletedData.tableHeaders;
		},
		tableData() {
			return this.$store.state.InterviewCompletedData.tableData;
		},
		interviewCount() {
			return this.$store.state.InterviewCompletedData.count;
		},
        profileData() {
			if (this.isOpen) {
				return this.tableData.find((data) => {
					return data.id === this.applicantID;
				});
			} else {
				return this.tableData;
			}
		},
		fetchedData() {
			return this.$store.state.CoursesData;
		},
		courseList() {
			return this.fetchedData.totalSubjectList;
		},
		count(){
			return this.$store.state.ParentData.parentCount;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		// pagedTableData() {
		// 	var start = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(start, start + this.itemsPerPage);
		// },
		cardData(){
			return this.$store.state.DashboardData.interviewCompletedCardData;
		},
	},
	methods: {
		rejectChanges() {		
			this.applicantData.id = this.loadedData.id
			this.applicantData.is_rejected = true
			this.applicantData.is_verified = false
			
			this.$store
				.dispatch("setInterviewCompletedDetails", {applicantData:this.applicantData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen()
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to update teacher",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
			
		},
		applyChanges() {
			var error = undefined;
			if (this.applicantData.meet_url == '') {
				error = "Meet url is required";
			}
			if(!this.applicantData.meet_url.includes('https://')){	
				this.applicantData.meet_url='https://'+this.applicantData.meet_url;
			}
			
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.applicantData.id = this.loadedData.id
			this.applicantData.is_verified = true
			this.applicantData.is_rejected = false

			if(this.applicantData.subjects.length == 0){
				this.applicantData.subjects = this.loadedData.subjects
			} else {
				this.applicantData.subjects=this.applicantData.subjects.map(item=>item.id)
			}
			
			this.$store
				.dispatch("setInterviewCompletedDetails", {applicantData:this.applicantData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen();
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to update teacher",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
			
		},
        isModalOpen() {
			this.isOpen = !this.isOpen;
            this.fetchInterviewCompletedList()
		},
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.applicantID = id;
            this.checkInterviewCompletedDetails();
		},
		closeModal() {
			this.isOpen = !this.isOpen;
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		redirectTo(data) {
			if (data === "red" && this.$route.path != "/tms") {
				this.$router.push("/tms");
			} else if(data === "green" && this.$route.path != "/tms") {
				this.$router.push("/leave-applications");
			} else {
				this.$router.push("/new-applicants");
			}
		},
        fetchInterviewCompletedList() {
			this.$store.dispatch("fetchInterviewCompletedList",{itemsPerPage:this.itemsPerPage,startIndex:this.startIndex}).catch((err) => {
				this.$notify({
					title: "Failed to fetch interview completed list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				})
			})

		},
        checkInterviewCompletedDetails() {
			if (this.applicantID !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid applicant ID " + this.applicantID,
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch(
								"fetchInterviewCompletedDetails",
								this.applicantID
							)
							.then(() => {
								this.prepareDetails();
							})
							.catch((err) => {
								this.$notify({
									title: "Failed to fetch applicant details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString(),
								});
							});
					} else {
						this.prepareDetails();
					}
				}
			}
		},
		prepareDetails() {
			this.loadedData = JSON.parse(JSON.stringify(this.profileData));
		},
		fetchTeacherDashboardData() {
			this.$store
				.dispatch("fetchTeacherDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchTotalSubjectList() {
			this.$store.dispatch("fetchTotalSubjectList").catch((err) => {
				this.$notify({
					title: "Failed to fetch course list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		fetchNewAppliacantsCount() {
			this.$store
				.dispatch("fetchNewAppliacantCount")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		LeaveApplicationsCount(){
			this.$store.dispatch("LeaveApplicationsCount",{status:'pending'}).then(()=>{
			}).catch((err) => {
				this.$notify({
					title: "Failed to fetch Leave application",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});

		},
		

	},
	watch:{
			// subject(){
			// 	if(this.applicantData.subjects.length > 0){
			// 		this.applicantData.subjects=this.applicantData.subjects.map(item=>item.id);
			// 	}else{
			// 		this.applicantData.subjects=this.loadedData.subjects;
			// 	}
			// }
			currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchInterviewCompletedList();
		}
		},

	mounted(){
		this.fetchInterviewCompletedList();
		this.fetchTeacherDashboardData();
		this.fetchTotalSubjectList();
		this.fetchNewAppliacantsCount()
		this.LeaveApplicationsCount()
	}
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}
.search #input {
	border-radius: 50px;
	width: 360px;
}
.custom-card {
	width: 250px !important;
	border-radius: 21px !important;
}
.custom-input input{
    width: 250px !important;
	height: 21px;
	outline: none;
	
}
.custom-edit-input input{

	border: none;
    border-bottom: 1px solid #121619;
    color: black;
	transition: .3s all linear
    
}
.custom-edit-input input:focus{
	outline: none;
	}
.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	margin-bottom: 3px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}

</style>