<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class=" pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div class="col-sm-12 head-section">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									Teachers Management / ALL
								</h2>
								<div class="row d-flex justify-content-between">
									<form class="col-12 col-sm-7">
										<div class="form-row mt-4">
											<!-- <div :class="notification ? 'col-sm-4 col-0':'col-sm-3 col-0'">
												<select
													class="rounded m-0 pl-1 form-control input-group-alternative"
												>
													<option selected value="ID">
														ID
													</option>
													<option value="Name">
														Name
													</option>
													<option
														value="Subject"
													>Subject
														
													</option>
												</select>
											</div> -->
											<div
												class="search col-12 mt-2 mt-sm-0 col-sm-5"
											>
												<base-input
													placeholder="Search"
													class="input-group-alternative"
													addon-right-icon="fas fa-search"
													v-model="searchTerm"
												>
												</base-input>
											</div>
											<div class="col-12 col-sm-3">
												<base-button
												@click="search"
													type="primary"
													class="col-12"
													outline
													>Search</base-button
												>
											</div>
										</div>
									</form>
									<div
										class="col-12 col-sm-4 d-flex justify-content-end mt-5 mt-sm-0 align-items-center"
									>
										<base-button
											@click.prevent="addNew()"
											type="primary"
											outline
											class="col-12 col-sm-6"

											>Add New +</base-button
										>
									</div>
								</div>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div class=" table-responsive ">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pr-2 pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
										<th class="pl-2 text-center">Mobile</th>
										<th class="pl-0 text-center">total students</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">
											{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-lg-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-3 mr-5">
													{{ row.teacher_id}}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-3 name-row">
											<div
												class="row d-flex mr-2 align-items-center justify-content-center"
											>
												<div class="col-1">
													<img
														:src="(row.profile_picture && row.profile_picture.thumbnail) ? row.profile_picture.thumbnail : 'img/user1.jpg'"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}</span
														>
													</div>
													<div class="col-12">
														<span
															>{{row.qualification}}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td class="col-3 subject-row">
											<div class="row d-flex align-items-center justify-content-center">
												{{row.mobile}}
											</div>
										</td>
										<td class=" col-3 ">
											<div
												class="row d-flex justify-content-center"
											>
												{{ row.total_students }}
											</div>
										</td>
									</template>
								</base-table>
							</div>
							<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="teacherCount"
								v-model="currentPage"
								align="center"
							/>
						</card>
						<div class="row mt-4 d-flex justify-content-around">
							<div
								v-for="(data, index) in teacherCardData"
								:key="index"
								class= 'card-sm custom-card rounded shadow bg-white py-3 my-3 my-sm-0 py-sm-3 col-sm mx-2 col-12'
							>
								<div
									:class="
										data.color === 'red'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
									:style="
										data.color === 'red'
											? 'background-color:#9EBF6D;'
											: data.color === 'blue' ? 'background-color:#EB5757;' : 'background-color:#F46399'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm text-nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<div
										@click.prevent="redirectTo(data.color)"
										class="btn col-2"
									>
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="teacherCount"
						:current-page="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<TmsModal
		v-if="isOpen"
			:is-open="isOpen"
			:is-modal-open="isModalOpen"
			:student-id="studentId"
		/>
		<TMSAddModal
			v-if="addOpen"
			:addOpen="addOpen"
			:closeAddModal="closeAddModal"
		/>
	</div>
</template>
<script>
import TmsModal from "./Modals/TmsModal.vue";
import TMSAddModal from "./Modals/TMSAddModal.vue";
import Notification from '../../components/Notification.vue';
export default {
	name: "STMS",
	components: {
		TmsModal,
		TMSAddModal,
		Notification
	},

	data() {
		return {
			isOpen: false,
			itemsPerPage: 15,
			currentPage: 1,
			startIndex:0,
			studentId: "",
			addOpen: false,
			subjectLikeToTeach:'English',
			searchTerm:'',
		};
	},
	computed: {
		tableData() {
			return this.$store.state.TmsData.tableData;
		},
		teacherCount() {
			return this.$store.state.TmsData.teacherCount;
		},
		tableHeader() {
			return this.$store.state.TmsData.tableHeaders;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "34px",
				width: "34px",
				background: "#2194f6",
			};
		},
		// pagedTableData() {
		// 	var start = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(start, start + this.itemsPerPage);
		// },
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		teacherCardData(){
			return this.$store.state.DashboardData.tmsCardData;
		},
		
		
	},
	methods: {
		closeAddModal() {
			this.addOpen = !this.addOpen;
			this.fetchTeachersList();
		},
		isModalOpen() {
			this.isOpen = !this.isOpen;
			this.fetchTeachersList();
		},
		search(){
			if(this.searchTerm !==''){
				this.fetchTeachersList();
			}
		},

		redirectTo(data) {
			if (data === "red" && this.$route.path != "/new-applicants") {
				this.$router.push("/new-applicants");
			} else if (
				data === "blue" &&
				this.$route.path != "/leave-applications"
			) {
				this.$router.push("/leave-applications");
			}else {
				this.$router.push("/interview-completed");
			}
		},
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.studentId = id;
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		fetchTeachersList() {
			this.$store
				.dispatch("fetchTeachersList",{searchTerm:this.searchTerm,itemsPerPage:this.itemsPerPage,startIndex:this.startIndex})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Teachers",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchTeacherDashboardData() {
			this.$store
				.dispatch("fetchTeacherDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchNewAppliacantsCount() {
			this.$store
				.dispatch("fetchNewAppliacantCount")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		InterviewCompletedCount() {
			this.$store.dispatch("InterviewCompletedCount").catch((err) => {
				this.$notify({
					title: "Failed to fetch interview completed list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				})
			})

		},	
		LeaveApplicationsCount(){
			this.$store.dispatch("LeaveApplicationsCount",{status:'pending'}).then(()=>{
			}).catch((err) => {
				this.$notify({
					title: "Failed to fetch Leave application",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});

		},
		fetchInterviewCompletedList() {
			this.$store.dispatch("fetchInterviewCompletedList",{itemsPerPage:'',startIndex:''}).catch((err) => {
				this.$notify({
					title: "Failed to fetch interview completed list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				})
			})

		},

	},
	watch:{
		searchTerm(){
			if(this.searchTerm==''){
				this.fetchTeachersList();
			}
		},
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchTeachersList();
		}
	},
	mounted() {		
		this.fetchTeachersList();
		this.fetchInterviewCompletedList()
		this.fetchTeacherDashboardData();
		this.fetchNewAppliacantsCount();
		this.InterviewCompletedCount();
		this.LeaveApplicationsCount();
	},
};
</script>
<style scoped>
.search #input {
	border-radius: 50px;
	width: 360px;
}

.custom-card {
	width: 250px !important;
	border-radius: 21px !important;
}
.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
  .notif-section {
	display: none;
  }
   .head-section {
	display: flex;
	flex-direction: column;
 }
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
.name-row {
	min-width: 25vw;
	width: 100rem !important;
}
.subject-row {
	min-width: 18vw;
	width: 100rem !important;
}

</style>
