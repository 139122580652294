<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row pb-4">
							<div class="col-sm-12 head-section">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									Parents / All
								</h2>
								<div class="row d-flex justify-content-between">
									<!-- <form class="col-12 col-sm-7">
										<div class="form-row mt-4">
											<div
												class="search col-12 mt-2 mt-sm-0 col-sm-5"
											>
												<base-input
													placeholder="Search"
													class="input-group-alternative"
													addon-right-icon="fas fa-search"
												>
												</base-input>
											</div>
											<div class="col-12 col-sm-3">
												<base-button
													type="primary"
													class="col-12"
													outline
													>Search</base-button
												>
											</div>
										</div>
									</form> -->
								</div>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div class="table-responsive">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">
											{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-lg-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-2 mr-4">
													{{ row.id !=undefined ?row.id.slice(0,3):row.id }}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-4 name-row">
											<div
												class="row d-flex align-items-center"
											>
												<div class="col-1">
													<img
														src="img/user1.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9 ml-1">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}</span
														>
													</div>
													<!-- <div class="col-12">
														<span
															>2nd standard, age
															4</span
														>
													</div> -->
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex mr-2 align-items-center justify-content-center"
											>
												{{row.district}}, {{ row.location }}
											</div>
										</td>
                                        <td class="col-4">
											<div
												class="row d-flex mr-2 align-items-center justify-content-center"
											>
												{{ row.mobile}}
											</div>
										</td>
									</template>
								</base-table>
							</div>
							<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="count"
								v-model="currentPage"
								align="center"
							/>
						</card>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="count"
						:currentPage="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
        <ParentModal
        v-if="isOpen"
        :is-open='isOpen'
        :close-modal="closeModal"
        :parent-id='parentId'
        />

	</div>
</template>
<script>
// import NotificationPanel from '@/layout/NotificationPanel.vue';
import ParentModal from "./Modals/ParentModal.vue";
import Notification from '../../components/Notification.vue';
export default {
	name: "Parents",
	components: {
        ParentModal,
		Notification
	},

	data() {
		return {
			isOpen: false,			
			itemsPerPage: 15,
			currentPage: 1,
			startIndex:0,
			parentId: null,
		};
	},
	computed: {
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		tableHeader() {
			return this.$store.state.ParentData.tableHeaders;
		},
		tableData() {
			return this.$store.state.ParentData.parent_tableData;
		},
		count(){
			return this.$store.state.ParentData.parentCount;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		// pagedTableData() {
		// 	var start = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(start, start + this.itemsPerPage);
		// },
	},
	methods: {
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.parentId = id;		
		},
		closeModal() {
			this.isOpen = !this.isOpen;
			this.fetchParentList();
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		fetchParentList() {
			this.$store
				.dispatch("fetchParentList",{itemsPerPage:this.itemsPerPage,startIndex:this.startIndex})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch users",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},

	},
	watch:{
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchParentList();
		}
	},

	mounted(){
		this.fetchParentList();
	}
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}
.search #input {
	border-radius: 50px;
	width: 360px;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 10vw;
	width: 100rem !important;
}
.name-row {
	min-width: 25vw;
	width: 100rem !important;
}
</style>