<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div
						:class="
							notification
								? 'col-md-10 col-sm-10'
								: 'col-md-12 col-sm-12'
						"
					>
						<div class="row">
							<div class="col-sm-12">
								<h3 class="text-orange">
									Hey {{ $store.state.profileData.data.name}}
								</h3>
								<h2 class="text-black font-weight-bold">
									Demo Class Requests
								</h2>
								<div class="row">
									<select
										v-model="demoStatus"
										@change="fetchDemoClassRequests"
										class="form-control input-group-alternative py-0 mt-4 mr-3 ml-4"
										style="width: 180px"
										id="state"
									>
										<option selected value="False">
											Pending
										</option>
										<option value="True">Accepted</option>
									</select>
								</div>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div
								v-if="tableList.length > 0"
								class="table-responsive"
							>
								<base-table
									class="align-items-center"
									:data="tableList"
								>
									<template slot="columns">
										<th class="pl-0">sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">{{ index + 1 }}</td>
										<td class="col-lg-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-2 mr-3">
													{{
														row.id != undefined
															? row.id.slice(0, 3)
															: row.id
													}}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2 ml-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-1">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{ row.name }}
														</span>
													</div>
													<div class="col-12">
														<span
															>Standard
															{{
																row.student_class
															}}, age
															{{ row.age }}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td class="col-3">
											{{ row.date }}
										</td>
										<td class="col-3 text-center">
											{{ timeConvert(row.time) }}
										</td>
									</template>
								</base-table>
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pl-0">sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
								</base-table>
								<div
									class="col-12 d-flex flex-column justify-content-center align-items-center"
								>
									<img
										src="img/undraw.png"
										height="250px"
										width="250px"
										alt="No data found"
									/>
									<h3 class="text-muted mr-4">
										Oops! No data found
									</h3>
								</div>
							</div>
						</card>
						<div class="row mt-4 d-flex justify-content-around">
							<div
								v-for="(data, index) in dashboardData"
								:key="index"
								class="card-sm custom-card rounded py-3 my-3 my-sm-0 py-sm-3 col-sm-3 mx-2 col-12 shadow bg-white"
							>
								<div
									:class="
										data.head === 'Payment Pending'
											? 'num-card col-3  ml-3 rounded text-center'
											: data.head === 'Demo Class Request'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
									:style="
										data.head === 'Payment Pending'
											? 'background-color:#F35D94'
											: data.head === 'Demo Class Request'
											? 'background-color:#9B51E0'
											: 'background-color:#9EBF6D'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<!-- <div class="btn col-2">
										<i class="fa fa-angle-right"></i>
									</div> -->
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification" class="col-2 notif-section">
						<Notification
						:count="10"
						:currentPage="0" 
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
		<DemoClassModal
			v-if="isOpen"
			:is-open="isOpen"
			:is-modal-open="isModalOpen"
			:student-id="studentId"
			:demo-status="demoStatus"
		/>
	</div>
</template>
<script>
import DemoClassModal from "./Modal/DemoClassModal.vue";
import Notification from "../../components/Notification.vue";
// import NotificationPanel from '@/layout/NotificationPanel.vue';
export default {
	name: "demo-class",
	components: {
		DemoClassModal,
		Notification,
	},

	data() {
		return {
			demoStatus: "False",
			isOpen: false,
			studentId: "",
		};
	},
	computed: {
		tableData() {
			return this.$store.state.DemoClassData.tableData;
		},
		tableHeader() {
			return this.$store.state.DemoClassData.tableHeader;
		},
		fetchedData() {
			return this.$store.state.DemoClassData;
		},
		tableList() {
			return this.fetchedData.fetchedData;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "39px",
				width: "34px",
				background: "#2194f6",
			};
		},
		notification() {
			return this.$store.state.notifications.notificationStatus;
		},
		dashboardData() {
			return this.$store.state.DashboardData.demclassCardData;
		},
	},
	methods: {
		fetchDemoClassRequests() {
			this.$store
				.dispatch("fetchDemoClassRequests", { status: this.demoStatus })
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch demo class list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		isModalOpen() {
			this.isOpen = !this.isOpen;
			this.fetchDemoClassRequests();
		},
		openModal(id) {
			this.studentId = id;
			this.isOpen = !this.isOpen;
		},
		fetchStudentDashboardData() {
			this.$store.dispatch("fetchStudentDashboardData").catch((err) => {
				this.$notify({
					title: "Failed to fetch Data",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		timeConvert (time) {
			time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
			if (time.length > 1) { 
			time = time.slice (1);  
			time[5] = +time[0] < 12 ? 'AM' : 'PM'; 
			time[0] = +time[0] % 12 || 12; 
			}
			return time.join ('').replace(':00'," "); 
		}

	},

	mounted() {
		this.fetchDemoClassRequests();
		this.fetchStudentDashboardData();

		//Fetching teachers list here so that when the model renders we'll get the full teachers count for filtering out the active teacher
		this.$store
			.dispatch("fetchTeachersList", {
				searchTerm: "",
				itemsPerPage: "",
				startIndex: "",
			})
			.catch((err) => {
				this.$notify({
					title: "Failed to fetch Teachers",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
	},
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}
.custom-card {
	width: 250px !important;
	border-radius: 20px !important;
}
.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
</style>
