<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div class="col-sm-12 head-section">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h3 class="text-black font-weight-bold">
									{{student.name}}'s Class details
								</h3>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div v-if="classData.length>0"  class="table-responsive">
								<base-table
									class="align-items-center"
									:data="classData"
								>
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th class="text-center">ID</th>
                                        <th class="text-center">Subject</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Teacher</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">
											{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-lg-3 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-3 mr-3 pl-0">
													{{row.id !== undefined? row.id.slice(0,4):row.id }}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="openModal(row.id)"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-3 subject-row text-center">
											{{row.subject}}
										</td>
                                        <td class="col-3 date-row">
											{{row.class_date !=null?row.class_date:'Set Date'}}
										</td>
                                        <td class="col-3 time-row">
											{{row.class_time !=null?row.class_time:'Set Time'}}
										</td>
										<td class="col-3 teacher-row">
											{{row.teacher}}
										</td>
										
									</template>
								</base-table>
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th class="text-center">ID</th>
                                        <th>Subject</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Teacher</th>
									</template>
								</base-table>
							<div  class="col-12 d-flex flex-column justify-content-center align-items-center">
									<img src="img/undraw.png" height="250px" width="250px" alt="No data found">
									<h3 class="text-muted mr-4">Oops! No data found</h3>
							</div>
								
						</div>
							<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="count"
								v-model="currentPage"
								align="center"
							/>
						</card>
					</div>
						<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="20"
						:currentPage="1"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- modal -->
	<modal 
		:show="isOpen" 
		@close="closeModal"
		modal-classes=" modal-dialog-centered  modal-md">
				<template v-slot:header>
					<div class="row">
					<h3 class="col-sm text-nowrap">
						Course Info :
					</h3>
					<div class="mt-0 ml-lg-7 pl-lg-8	">
						<base-button v-if="!isEdit"
							@click.prevent="isEdit = !isEdit"
							style="
								border-radius: 20px;
								background-color: #2f80ed;
							"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							
							>Edit</base-button
						>
						<base-button v-else
							@click.prevent="isEdit = !isEdit"
							style="
								border-radius: 20px;
								
							"
							class="visible  py-1 px-3  bg-danger"
							type="danger"
							size="sm"
							
							>Cancel</base-button
						>
					</div>
				</div>
			</template>
				<div class="form-group row d-flex">
					<label
						for="date"
						class="col-sm-4 col-form-label text-sm py-0 font-weight-bold"
						>Student Name</label
					>
					<div class="custom-input col-sm-6">
						<input
							type="text"
							style="width: 200px"
							readonly
							class="form-control-plaintext py-0"
							id="name"
							v-model="classProfile.student"
							
							
						/>
					</div>
				</div>
				<div class="form-group row">
						<label
							for="subject"
							class="col-sm-4 col-form-label text-sm py-0 font-weight-bold"
							>Subject</label
						>
						<div class="custom-input col-sm-6"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="subject"
								v-model="classProfile.subject"
								
							/>
						</div>
						
					</div>
					<div class="form-group row">
						<label
							for="teacher"
							class="col-sm-4 col-form-label text-sm py-0 font-weight-bold"
							>Teacher</label
						>
						<div
							class="custom-input col-sm-3"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="teacher"
								v-model="classProfile.teacher"
								
							/>
						</div>
					</div>
				<div class="form-group row">
						<label
							for="date"
							class="col-sm-4 col-form-label text-sm py-0 font-weight-bold"
							>Date</label
						>
						<div :class="!isEdit ? 'custom-input col-sm-6': 'custom-edit-input col-sm-6'"
						>
							<input
								type="date"
								:readonly="!isEdit"
								class="form-control-plaintext py-0"
								id="date"
								v-model="classProfile.class_date"
								
							/>
						</div>
					</div>
					<div class="form-group row">
						
						<label
							for="time"
							class="col-sm-4 col-form-label text-sm py-0 font-weight-bold"
							>Time </label
						>
						<div
							:class="!isEdit ? 'custom-input col-sm-6': 'custom-edit-input col-sm-6'"
						>
							<input
								type="time"
								:readonly="!isEdit"
								class="form-control-plaintext py-0"
								id="time"
								v-model="classProfile.class_time"
								
							/>
						</div>
					</div>
				<template v-slot:footer>
					<base-button
					v-if="isEdit"
								class="ml-2 px-4 bg-success"
								@click.prevent="save"
								style="	border-radius: 20px;"
								type="primary"
								size="sm"
								>Save</base-button
							>
				</template>
		</modal>
		<!-- </base-header> -->
	</div>
</template>
<script>
import Notification from '../../components/Notification.vue';
export default {
	components:{
		Notification
	},
    data(){
        return{
            itemsPerPage: 20,
			isEdit:false,
			currentPage: 1,
			startIndex:0,
			subjectID:'',
			isOpen:false,
			classProfile:{
				student:'',
				teacher:'',
				subject:'',
				class_date:null,
				class_time:null,
				class_number:null
			},
        }
    },
    computed:{
       tableData() {
			return this.$store.state.StmsData.tableData;
		},
		classData(){
			return this.$store.state.StudentClassData.student_class;
		},
		count(){
			return this.$store.state.StudentClassData.count;
		},
        notification(){
			return this.$store.state.notifications.notificationStatus;

		},
        tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
        
        
        studentID(){
            return this.$route.params.studentID;
        },
        student(){
            return this.tableData.find(item=>item.id==this.studentID)
        },
		profileData(){
			return this.classData.filter(item=>item.id==this.subjectID)
		},
		isLoaded(){
			return this.$store.state.StudentClassData.isLoaded;
		},
		stmsCount(){
			return this.$store.state.StmsData.stmsCount;
		},
    },
     methods: {
		save() {
			var error =undefined;
			if(! this.isLoaded){
				error = "Data is not loaded yet, try again later";
				this.fetchClassList();
			} 
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.$store
				.dispatch("setClassDetails", {studentID:this.studentID,subjectID:this.subjectID,
					subjectData:this.classProfile
				})
				.then(() =>this.closeModal())
				.catch(err => {
					this.$notify({
						title:
							"Failed to edit class",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});			

		},
		closeModal(){
			this.isOpen = !this.isOpen;
			this.fetchClassList()
		},
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.subjectID = id;
			this.checkClassDetails();
		},
           
		fetchSTMSList() {
			this.$store
				.dispatch("fetchSTMSList",{searchTerm:'',itemsPerPage:this.stmsCount,startIndex:''})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Stms",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchClassList() {
			this.$store
				.dispatch("fetchClassList",{studentID:this.studentID,itemsPerPage:this.itemsPerPage,startIndex:this.startIndex})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch class",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		checkClassDetails() {
			if (this.subjectID !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid subject ID " + this.subjectID
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch("fetchClassDetails", {studentID:this.studentID,subjectID:this.subjectID})
							.then(() => {
								this.prepareClassData();
							})
							.catch(err => {
								this.$notify({
									title: "Failed to fetch class details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString()
								});
							});
					} else {
						this.prepareData();
					}
				}
			}
		},
		prepareClassData() {
			this.classProfile = JSON.parse(JSON.stringify(this.profileData[0]));
		},
    },
	watch:{
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchSTMSList();
		}
	},
    mounted(){
        this.fetchSTMSList();
		this.fetchClassList();	
		console.log(this.classData)
				
    }
    
}
</script>
<style scoped>

.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.custom-input input {
	width: 250px !important;
	height: 21px;
	outline: none;
	border: none;
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
	outline: none;
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
.time-row {
	min-width: 13vw;
	width: 100rem !important;
} 
.date-row {
	min-width: 13vw;
	width: 100rem !important;
} 
.teacher-row {
	min-width: 10vw;
	width: 100rem !important;
}
</style>