<template>
    <div>    
		<div class="ml--2 card notif-card" 
		:style="size==currentPage&&((count/15)%1)<.2?'max-height:25rem !important;'
		:size==currentPage&&((count/15)%1)<.4?'max-height:30rem !important;'
		:size==currentPage&&((count/15)%1)<.6?'max-height:47rem !important;'
		:size==currentPage&&((count/15)%1)<.95?'max-height:73rem !important;'
		:count <=5?'max-height:25rem !important;':count <=10?'max-height:45rem !important;':'max-height:79rem !important;'" >
			<h2  class="pl-4 pt-4 mb-1" font-weight-bold>Notifications</h2>
			<div class="col pl-3 ">
				<div id="main-container" :class="data.status=='unread' ?'pt-2 pb-10 ':' pt-2 pb-10 '"  v-for="(data,idx) in notificationsList" :key="idx">
					<div  :class="data.status=='unread' ?'card red':' noti-card'">
						<div  :class="data.status=='unread' ?'card blue':' noti-card'">
                            <div @click.prevent="clickNotif(data)" :style="data.status=='read'?'background-color:#EEF2FF':''" class="card  noti-card mr-3">
								<div class=" d-flex justify-content-between ml-2 mt-3 align-items-center">
									<div  :style="data.title.includes('counseling')||data.title.includes('Class')?'background-color:#219653;':data.title.includes('Demo')?'background-color:#9B51E0;':'background-color:#F2994A;'"
											class="button px-3  d-flex align-items-center justify-content-center text-center text-white"
											
											><span class="">{{data.title.includes('counseling')?'C':data.title.includes('Leave')||data.title.includes('interview')?'T':data.title.includes('Class')?'S':data.title.includes('Demo')?'D':'C'}}</span>
                                    </div>
										<div :style="data.title.includes('counseling')||data.title.includes('Class')?'color:#219653;':data.title.includes('Demo')?'color:#9B51E0;':'color:#F2994A;'" class="mr-3 text-sm">{{data.title.includes('counseling')?'Counseling':data.title}}</div>
								</div>							
								<div class="text-sm text-dark mt-2 pt-1 ml-2">
										{{data.title.includes('counseling')||data.title.includes('Demo')?data.extra_params.for:data.title.includes('Class')?data.extra_params.student:data.title.includes('Leave')?data.extra_params.teacher_name:data.title.includes('Course')?data.extra_params.student:data.title.includes('interview')?data.extra_params.from:''}}
								</div>
								<div style="font-size:3vw" class=" ml-2 ">
									<p style="font-size: 1.1vw;" class="py-0 text-nowrap ">{{data.title.includes('Course')?truncate(data.extra_params.subject):data.title.includes('counseling')||data.title.includes('Demo')||data.title.includes('Class')?`${data.extra_params.date}&${data.extra_params.time.slice(0,5)}`:data.title.includes('Leave')?`${data.extra_params.start_date}`:`${data.extra_params.date}&${data.extra_params.time.slice(0,5)}`}}</p>
									
							</div>
							</div>
						</div>
					</div>							
				</div>
                </div>
		</div>
    </div>
</template>
<script>
export default {
	props: [ 'count','currentPage'],
	data() {
		return {
			notificationStatus:{
				status:''
			},
			notificationID:null	,
			size:null
		};
	},
	computed: {	
		notificationsList(){
			return this.$store.state.notificationData.notificationsList;
		},
		
	},
	methods: {
		truncate(str){
			if(str.length > 17) {
				return str.slice(0,17)+"...";
			}
			else {
				return str
			}
		},
		clickNotif(data){
			this.notificationID=data.id;
			if(data.title=='New counseling'&&this.$route.path != "/counseling"){
				this.$router.push('/counseling')
				console.log(this.notificationsList);
			}else if(data.title=='Demo class'&&this.$route.path != "/demo-class"){
				this.$router.push('/demo-class')
			}else if(data.title=='Leave request'&&this.$route.path != "/leave-applications"){
				this.$router.push('/leave-applications')
			}else if(data.title=='New interview'&&this.$route.path != "/new-applicants"){
				this.$router.push('/new-applicants')
			}else if(data.title=='Course created'||data.title=='Class created'&&this.$route.path != "/stms"){
				this.$router.push('/stms')
			}
			this.updateNotification();
		
		},
		height(){
			let count =(this.count/15)%1;
			if(count !=1&&this.count>10){
				if(count<.5){
					this.size=Math.round(this.count/15)+1;
				}else if(count <.99){
					this.size=Math.round(this.count/15);
				}
			}else{
				this.size=0;
			}
			
		},
		updateNotification() {
			this.notificationStatus.status='read'
			this.$store
				.dispatch("setNotification",{notificationStatus:this.notificationStatus,notificationID:this.notificationID})
				.then(()=>{this.fetchNotifications()})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch notification details",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchNotifications() {
			console.log("this is running")
			this.$store
				.dispatch("fetchNotificationsList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch notifications",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
	},
	watch:{
		currentPage(){
			this.height();
		}
	},
	mounted() {
		
	},
};
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}

html, body {
    height:100%;
}
#main-container{
	position: relative;
	margin-top: 10px;
}
.notif-card {   
	min-width: 14.4vw !important;
	background-color: #fff2f8;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 20px;
	border: none;
    transition: ease-in;
	animation-name: example;
    animation-duration: 8s;
	overflow-y: scroll;	
	
}
::-webkit-scrollbar {
  display: none;
}
.noti-card{
	min-width: 12.4vw !important;
	height:19.2vh;	
	border-radius: 20px !important;
	position: relative;
	bottom: 3px;
	left: -2px;
	cursor: pointer;
	position: relative;
}

.red{
	position: relative;	
	width: 12.3vw;
	height:19.2vh;
	border-radius: 20px !important;
	background-color: #9B51E0;
	left: -1px;
}
.blue{
	position: relative;
	width: 12.3vw;
	height:19.2vh;
	border-radius: 20px !important;
	bottom: 3px;
	background-color: #BB6BD9;			
}
.button {
    width:20px !important;
    height:30px !important;
    border-radius:10px

}
@keyframes example {
   from { min-height: 0%;}
  to { min-height: 20%;}
}

</style>