<template>
	<div>
		<!-- assessmentModal -->
		<modal
			class="modal modal2"
			:show="isAsessmentOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAssessmentModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h2>Assessments</h2>
			</template>
			<div v-if="assessmentCount>0">
				<div v-for="(data,idx) in assessmentData" :key="idx"  class="row py-4">
					<div class="col-4 d-flex align-items-center pl-5">
						<div class="row d-flex align-items-center justify-content-center">
							<img
								src="img/admin.jpg"
								width="40px"
								height="40px"
								alt=""
								class="rounded"/> 
							<div class="col">
								<p class="font-weight-bold my-0">{{data.author_name}}</p>
								<p class="my-0">{{data.date_added.slice(0,10)}}</p>
							</div>
						</div>
					</div>
					
					<div class="col-8 assessment-text text-wrap">
						<p class=" py-0">{{data.body}}</p
						>
					</div>
					
				</div>								
			</div>
			<div v-else>
						<h4
							class="d-flex align-items-center text-muted justify-content-center p-5"
						>
							No Assessment Found
						</h4>
			</div>
			
		</modal>
		<modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isModalOpen"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="col-6">
					Student Profile :
					<span class="text-gray"> Demo Class</span>
					
				</h3>
				<div :class="demoStatus=='False'?'row ml-lg-7 pl-lg-4':'row ml-lg-7 pl-lg-8'">
					<base-button
					@click.prevent="isAsessmentOpen = !isAsessmentOpen"
						style="
							border-radius: 20px;
							color: black;
							background-color: #ffff;
							border: 1px solid rgba(158, 152, 155, 0.4);
							box-sizing: border-box;
						"
						class="pr-3 text-black"
						size="sm"
						icon="fas fa-sticky-note"
						type="primary"
						>Assessment</base-button
					>
					<div v-if="demoStatus=='False'">
					<base-button
						v-if="profileEdit"
						@click.prevent="profileEdit = !profileEdit"
						style="border-radius: 20px; background-color: #2f80ed"
						class="hidden py-1 pl-4 pr-4"
						type="primary"
						size="sm"
						>Edit</base-button
					>
					<base-button
						v-else
						@click.prevent="profileEdit = !profileEdit"
						style="border-radius: 20px"
						class="visible py-1 px-3 bg-danger"
						type="danger"
						size="sm"
						>Cancel</base-button
					>
					</div>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-sm-3">
							<span class="py-0 text-small">{{profileData.id && profileData.id.slice(0,3)}}</span>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-input col-sm-3">
							
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.name"
							/>
							
						</div>
						<label
							for="parentName"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Class</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="parentName"
								v-model="profileData.student_class"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Age</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="number"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.age"
							/>
						</div>
						<label
							for="parentName"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Guardian</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="parentName"
								v-model="profileData.guardian"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="profileData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-input col-sm-3">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="profileData.whatsapp_number"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Date</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-sm-3 '
									: 'custom-edit-input col-sm-3 '
							"
						>
							<input
								type="date"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="loadedData.date"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Time</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-sm-3 '
									: 'custom-edit-input col-sm-3 '
							"
						>
							<input
								type="time"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="loadedData.time"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Training Link</label
						>
						<div
							:class="
								edit
									? 'custom-input col-sm-4 d-flex '
									: 'custom-edit-input col-sm-4 d-flex '"
						>
							<input type="text"
								v-if="(loadedData.meet_url!=null && loadedData.meet_url.length>0) || !edit"
								:readonly="edit"
								style="width: 300px"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="loadedData.meet_url"
							/>
							<div v-if="demoStatus=='False'" class="col-sm-2 d-flex justify-content-center align-items-center">
								<base-button
									v-if="edit"
									class="ml-4"
									@click="updateUrl"
									style="
										background-color: #2f80ed;
										border-radius: 20px;
									"
									type="primary"
									size="sm"
									>Update</base-button
								>
								<base-button
									v-else
									@click="edit = !edit"
									style="border-radius: 20px"
									type="primary"
									size="sm"
									>Save</base-button
								>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="teacher"
							class="col-sm-3 pt-2 col-form-label py-0 text-sm font-weight-bold"
							>Teachers</label
						>
						<div v-if="profileEdit" class="col-sm-4">
							<base-button v-if="profileData.teacher !=null" type="success" size="sm"  @click.prevent="">{{currentTeacher}}</base-button>
							<span v-else class="text-warning">No teacher selected</span>
						</div>

						<div
						v-else
							class="custom-edit-input col-sm-8 d-flex align-items-center
							"
						>
							<multiselect
								class="custom-multi-select"
								:multiple="false"
								v-model="loadedData.teacher"
								:options="teachersList"
								label="name"
								track-by="name"
                                :hide-selected="false"
                                :close-on-select="true"
							>
							<template slot="singleLabel" slot-scope="{ option }">
								{{option.name}}
							</template>
							</multiselect>
							<div
								style="
									width: 34px;
									height: 39px;
									border-radius: 95px;
									background: #2194f6;
								"
								class="btn ml-3 rounded-circle d-flex justify-content-center align-items-center"
							>
								<i class="fa fa-plus p-3 text-white" />
							</div>
						</div>
					</div>
					<div class="form-group row assesment">
						<label
							for="assesment"
							class="col-sm-3 col-form-label py-0 text-sm font-weight-bold"
							>Assessment</label
						>
						<div class="col-sm-9 d-flex">
							<textarea
								type="text"
								rows="5"
								class="form-control py-0"
								id="assesment"
								v-model="assessData.body"
								placeholder="Assessment details type here"
								:readonly="assessEdit"
							></textarea>
							<div class="ml-3">
								<base-button
								v-if="assessEdit"								
									style="
										width: 95px;
										height: 26px;
										border-radius: 20px;
										color: black;
										background-color: #ffff;
										border: 1px solid
										rgba(158, 152, 155, 0.4);
										box-sizing: border-box;
									"
									class="mr-0 text-muted"
									size="sm"
									icon="fas fa-sticky-note  text-muted"
									type="primary"
									@click.prevent="assessEdit=!assessEdit"
									>Edit</base-button
								>
								<div v-else >
								<base-button
																
									style="
										width: 95px;
										height: 26px;
										border-radius: 20px;
										color: black;	
										border: 1px solid
										rgba(158, 152, 155, 0.4);
										box-sizing: border-box;"
									class="mr-0 mb-2"
									size="sm"
									type="success"
									@click.prevent="assessmentSave"
									>Save</base-button
								><base-button																
									style="
										width: 95px;
										height: 26px;
										border-radius: 20px;
										color: black;	
										border: 1px solid
										rgba(245, 7, 7, 0.4);
										box-sizing: border-box;
									"
									class="mr-0"
									size="sm"
									type="danger"
									@click.prevent="assessEdit=!assessEdit"
									>Cancel</base-button
								>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					v-if="demoStatus=='False'&&(!profileEdit||edit)"
					type="primary pl-4 pr-4"
					style="
						width: 160px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="applyChanges"
					>Update</base-button
				>
			</template>
		</modal>		
	</div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
	components: {
		Multiselect,
	},
	props: ["isOpen", "isModalOpen",'studentId','demoStatus'],
	data() {
		return {
			loadedData: {
				date:"",
				time:"",
				meet_url:"",
				teacher:"",
				admin_approval:false
			},
			outputData:{
				date:"",
				time:"",
				meet_url:"",
				teacher:"",
				admin_approval:false
			},
			profileEdit: true,
			edit: true,
			linkEdit:false,
			teacherEdit: true,
			isAsessmentOpen:false,
			assessEdit:true,
			assessData:{
				author:null,
				body:'',			
			},
		};
	},
	computed:{
		assessmentData() {
			return this.$store.state.StmsData.assessmentData;			
		},
		assessmentCount(){
			return this.$store.state.StmsData.assessmentCount;
		},
		fetchedData() {
			return this.$store.state.DemoClassData;
		},
		tableList() {
			return this.fetchedData.fetchedData;
		},
		profileData() {
			console.log("inside profileData",this.tableList)
			return this.tableList.find((data) => {
				return data.id === this.studentId;
			});
		},
		teachersList() {
			return this.$store.state.TmsData.tableData;
		},
		selectedData(){
			return this.$store.state.DemoClassData.fetchedData.filter(item=>item.id==this.studentId)
		},
		isLoaded(){
			return this.$store.state.DemoClassData.isLoaded;
		},
		teacherCount() {
			return this.$store.state.TmsData.teacherCount;
		},
		currentTeacher() {
			if(this.profileData.teacher != null ){
				let data = this.teachersList.filter(item=>item.id==this.profileData.teacher)
				// console.log("teachersList",this.teachersList)
				// console.log("profileData",this.profileData)
				return data[0].name
			}
			else{
				return "No teacher found"
			}
		}
	},
	methods: {
		applyChanges() {
			this.outputData.id=this.profileData.id 
			this.outputData.date=this.loadedData.date
			this.outputData.time=this.loadedData.time
			this.outputData.meet_url=this.loadedData.meet_url
			this.outputData.teacher=this.loadedData.teacher
			this.outputData.admin_approval=true
			var error = undefined;
			if (this.outputData.date.trim() == "") {
				error = "Class date is required";
			} else if (this.outputData.time.trim() == "") {
				error = "Class time is required";
			} else if (this.outputData.meet_url == null) {
				error = "Class URL is required";
			} else if (this.outputData.teacher == null) {
				error = "Teacher is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			
			this.loadedData.admin_approval = true			
			this.$store
				.dispatch("setDemoClassRequestDetails", {studentData:this.outputData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen();
					this.$store.dispatch('fetchNotificationsList');
					})
				.catch(err => {
					this.$notify({
						title:"Failed to update student",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});			
		},
		fetchDemoClassRequestsAndCheck() {
			this.$store
				.dispatch("fetchDemoClassRequests", { status: this.demoStatus })
				.then(()=>this.checkDemoClassDetails())
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch demo class list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		checkDemoClassDetails() {
			if (this.studentId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid demo class ID " + this.studentId,
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch(
								"fetchDemoClassRequestDetails",
								this.studentId
							)
							.then(() => {
								this.prepareDemoClass();
								console.log("this one", this.tableList)
								this.fetchStudentAssessment();
							})
							.catch((err) => {
								this.$notify({
									title: "Failed to fetch demo details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString(),
								});
							});
					} else {
						this.prepareDemoClass();
					}
				}
			}
		},
		prepareDemoClass() {
			this.loadedData = JSON.parse(JSON.stringify(this.profileData));
		},

		closeAssessmentModal(){
			this.isAsessmentOpen= !this.isAsessmentOpen;
		},
		updateUrl(){
			this.edit = !this.edit;
			this.linkEdit=true;
		},
		fetchStudentAssessment() {
			console.log("selected",this.selectedData)
			console.log(this.profileData)
			this.$store
			.dispatch("fetchStudentAssessment",{studentID:this.selectedData[0].student_id})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch assessment",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		assessmentSave(){
			var error =undefined;
			if(this.assessData.body==''){
				error=' Assessment must be addedd';
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.$store
				.dispatch("setStudentAssessment",{studentID:this.profileData.student_id,assessData:this.assessData})
				.then(() =>this.isModalOpen())
				.catch(err => {
					this.$notify({
						title:
							"Failed to add course",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
				this.assessEdit=!this.assessEdit;

		}
		,
			fetchTeachersList() {
			this.$store.dispatch("fetchTeachersList", {searchTerm:'',itemsPerPage:this.teacherCount,startIndex:''}).catch((err) => {
				this.$notify({
					title: "Failed to fetch Teachers",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
	},
	mounted() {
		this.fetchTeachersList();
		if (!this.isLoaded) {
			this.fetchDemoClassRequestsAndCheck();
		} else {
			this.checkDemoClassDetails();		
		}		
		
	},
};
</script>
<style scoped>
.custom-input input {
	height: 21px;
	outline: none;
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input input:focus {
	outline: none;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.modal2 {
	z-index: 2000;
} 
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}
.custom-multi-select >>> .multiselect__tags {
	border: none !important;
	margin: 0px !important;
	outline: none !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
