<template>
	<div>
		<!-- profileModal -->
		<modal
			class="modal"
			:show="addParentOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeAddParentModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<div class="row">
					<h3 class="col-sm">Add Parent</h3>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div class="custom-edit-input col-sm-4">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="name"
								v-model="parentData.name"
							/>
						</div>
					</div>
					
					<div class="form-group row">
						<label
							for="gender"
							class="col-sm-3 mt-3 mt-sm-0 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Gender</label
						>
						<div class="custom-edit-input col-sm-3 d-flex ">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="parentData.gender"
								id="gender">
								<option selected disabled value="">Choose</option>
								<option value="male">Male</option>
								<option value="female">Female</option> 
								<option value="other">Other</option>
	
							</select>
						</div>
					</div>					
                   <div class="form-group row">
						<label
							for="state"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>State</label
						>
						<div class="custom-edit-input col-sm-3 ">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="state"
								id="state"
							>
								<option selected disabled :value="null">Choose state</option>
								<option v-for="(state,idx) in states" :key="idx" :value="state.id">{{state.state}}</option>
	
							</select>
						</div>
						<label
							for="district"
							class="py-0 col-sm-2 mt-3 mt-sm-0 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold  "
							>District</label
						>
						<div class="custom-edit-input col-sm-3">
							<select							
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="height: 26px"
								v-model="parentData.district"
								id="district"
							>
								<option selected disabled :value="null">{{choose}}</option>
								<option v-for="(district,idx) in districts" :key="idx" :value="district.id">{{district.district}}</option>			
							</select>
						</div>
					</div>	
                    <div class="form-group row">
						<label
							for="location"
							class="py-0 col-sm-3 mb-2 mb-sm-0 text-sm col-form-label font-weight-bold"
							>Location</label
						>
						<div class="custom-edit-input col-sm-4">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="location"
								v-model="parentData.location"
							/>
						</div>
					</div>	
					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="parentData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 mt-3 mt-sm-0 mb-2 mb-sm-0 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div class="custom-edit-input col-sm-3">
							<input
								type="text"
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="parentData.whatsapp_number"
							/>
						</div>
					</div>			
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>

export default {
    props: ["addParentOpen", "closeAddParentModal","testFn"],
    data(){
        return{
            state:null,
            parentData:{
                name:'',
                mobile:'',
                whatsapp_number:'',
                gender:'',
                district:null,
                location:''
            }
        }
    },
    computed:{
        choose(){
			return this.state==null ? 'Choose state first':'Choose district'
		},
        states(){
			return this.$store.state.LocationData.states;
		},
		districts(){
			return this.$store.state.LocationData.districts;
		},
		
    },
    methods:{
        profileSave() {
			var error =undefined;
			if(this.parentData.name.trim()==''){
				error='Name is required';
			}else if(this.parentData.gender.trim()==''){
				error='Gender is required';
			}else if(this.parentData.district==null){
				error='District is required';
			}else if(this.parentData.location.trim()==''){
				error='Location is required';
			} else if(this.parentData.mobile==''){
				error='Mobile number is required';
			} else if(this.parentData.mobile.length !=10){
				error='Enter valid mobile number';
			}else if(this.parentData.whatsapp_number==''){
				error='Whatsapp number is required';
			}  else if(this.parentData.whatsapp_number.length !=10){
				error='Enter valid whatsapp number ';
			}
			if (error) {
				this.$notify({				
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.$store
				.dispatch("setParentDetails", {
					parentData:this.parentData
				})
				.then(() => this.closeAddParentModal())
				.catch(err => {
					this.$notify({
						title:"Failed to add parent",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
			});
		},
        fetchStateList() {
			this.$store
				.dispatch("fetchStateList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		// sendDataToParent(){
		// 	this.$emit('dataTransfer',123)
		// },
		fetchDistrictList() {
			this.$store
				.dispatch("fetchDistrictList",this.state)
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},

    },
    watch:{
        state(){
			this.fetchDistrictList();
		},
    },
    mounted(){
        this.fetchStateList();

    }
    
}
</script>
<style scoped>
.custom-edit-input input {	
	outline: none;
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}



</style>