<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div
						:class="
							notification
								? 'col-md-10 col-sm-10'
								: 'col-md-12 col-sm-12'
						"
					>
						<div class="row">
							<div class="col-sm-12">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									{{count}} New Registrations for Counseling
								</h2>
								<div class="row d-flex align-items-center">
									<select
										v-model="currentStatus"
										@change="fetchConselingList"
										class="form-control input-group-alternative py-0 ml-3 mt-3 text-dark"
										style="width: 180px; height: 36px"
										id="state"
									>
										<option selected value="False">
											Pending
										</option>
										<option value="True">Approved</option>
									</select>
									<div
										class="d-flex justify-content-end ml-auto"
									>
										<base-button
											@click.prevent="addNew"
											type="primary"
											outline
											class="mr-2 mt-3 w-60"
											>Add New +</base-button
										>
									</div>
								</div>
							</div>
						</div>
						<card class="mt-3" :style="tableStyle" rounded>
							<div v-if="tableData.length>0" class="table-responsive">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pr-2 pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">{{ itemsPerPage *(currentPage - 1) +index +1 }}</td>
										<td class="col-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-2 mr-4 pl-0">
													{{
														row.id != undefined
															? row.id.slice(0, 3)
															: row.id
													}}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-5">
											<div
												class="row mr-2 d-flex justify-content-center align-items-center"
											>
												<div class="col-1">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}</span
														>
													</div>
													<div class="col-12">
														<span
															>class
															{{
																row.student_class
															}}, age
															{{ row.age }}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td class="col-3 text-center">
											{{ row.date }}
										</td>
										<td  class="col-3 text-center">
											{{ timeConvert(row.time) }}
										</td>
									</template>
								</base-table>
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pr-2 pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
								</base-table>
							<div  class="col-12 d-flex flex-column justify-content-center align-items-center">
									<img src="img/undraw.png" height="250px" width="250px" alt="No data found">
									<h3 class="text-muted mr-4">Oops! No data found</h3>
							</div>
								
						</div>
							<base-pagination
							v-if="tableData.length>0"
								class="mt-5"
								:perPage="itemsPerPage"
								:total="count"
								v-model="currentPage"
								align="center"
							/>
						</card>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="count"
						:current-page="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
		<CouselingModal
			v-if="isOpen"
			:is-open="isOpen"
			:is-modal-open="isModalOpen"
			:student-id="studentId"
			:current-status="currentStatus"
		/>
		<!--Add Counseler modal-->
		<AddCounselerModal
			v-if="addOpen"
			:add-open="addOpen"
			:close-add-modal="closeAddModal"
		/>
	</div>
</template>
<script>
import CouselingModal from "./Modal/CouselingModal.vue";
import AddCounselerModal from "./Modal/AddCounselerModal.vue";
import Notification from '../../components/Notification.vue';
export default {
	components: { 
	CouselingModal,
	AddCounselerModal,
	Notification },

	data() {
		return {
			itemsPerPage:15,
			currentPage:1,
			startIndex:0,
			currentStatus: "False",
			isOpen: false,
			studentId:null,
			addOpen:false,
			student_name:'Rahul',
		};
	},
	computed: {
		notification() {
			return this.$store.state.notifications.notificationStatus;
		},
		tableData() {
			return this.$store.state.CounsellersData.tableData;
		},
		tableHeader() {
			return this.$store.state.CounsellersData.tableHeaders;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "34px",
				width: "34px",
				background: "#2194f6",
			};
		},
		// pagedTableData() {
		// 	var start = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(start, start + this.itemsPerPage);
		// },
		count(){
			return this.$store.state.CounsellersData.count;
		}
	},
	methods: {
		isModalOpen(){
        this.isOpen=!this.isOpen;
		this.fetchConselingList();

		},
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.studentId = id;
		},
		closeAddModal() {
			this.addOpen = !this.addOpen;
			this.fetchConselingList();
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		fetchConselingList() {
			this.$store
				.dispatch("fetchConselingList", { status: this.currentStatus,itemsPerPage:this.itemsPerPage,startIndex:this.startIndex })
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch counseling list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchSTMSList() {
			this.$store
				.dispatch("fetchSTMSList",{searchTerm:'',itemsPerPage:'',startIndex:''})
				.then(()=>{
					console.log(this.tableData)
				}).catch(err => {
					this.$notify({
						title: "Failed to fetch Stms",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		timeConvert (time) {
			time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
			if (time.length > 1) { 
			time = time.slice (1);  
			time[5] = +time[0] < 12 ? 'AM' : 'PM'; 
			time[0] = +time[0] % 12 || 12; 
			}
			return time.join ('').replace(':00'," "); 
		}
	},
	watch:{
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchConselingList();
		}
	},

	mounted() {
		this.fetchConselingList();
	},
};
</script>
<style scoped>
.custom-card {
	width: 250px !important;
	border-radius: 20px !important;
}
.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
</style>
