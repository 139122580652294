<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8  d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column ">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div class="col-sm-12 head-section">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									New {{newStudents}} students joined in this week, total {{totalStudents}} students
								</h2>
								<div class="row d-flex justify-content-between">
									<form class="col-12 col-sm-7">
										<div class="form-row mt-4">	
											<div
												class="search ml-0 ml-sm-4 col-12 mt-2 mt-sm-0 col-sm-5"
											>
												<base-input
													placeholder="Search"
													class="input-group-alternative"
													addon-right-icon="fas fa-search"
													v-model="searchTerm"
												>
												</base-input>
											</div>
											<div class="col-12 col-sm-3">
												<base-button
												@click.prevent="search"
													type="primary"
													class="col-12"
													outline
													>Search</base-button
												>
											</div>
										</div>
									</form>
									<div
										class="col-12 col-sm-4 d-flex justify-content-end mt-5 mt-sm-0 align-items-center"
									>
										<base-button
											@click.prevent="addNew()"
											type="primary"
											outline
											class="col-12 col-sm-6"
											
											>Add New +</base-button
										>
									</div>
								</div>
							</div>
						</div>
						<card class="mt-3 " :style="tableStyle" rounded>
							<div class="table-responsive">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
										<th>Class Detials</th>
									</template>
									<template slot-scope="{ row, index }">
										<td class="pl-0">
											{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-2 col-sm-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-6 col-sm-4 mr-4 mr-sm-4 pl-0">
													{{row.student_id }}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex align-items-center pl-2"
											>
												<div class="col-1">
													<img
														:src="(row.profile_picture && row.profile_picture.thumbnail) ? row.profile_picture.thumbnail : 'img/user1.jpg'"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-9 ml-1">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{
																row.name
															}}
															</span
														>
													</div>
													<div class="col-12">
														<span
															>standard {{row.student_class}} , age
															{{row.age}}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td v-if=" row.transaction !=null"
											:class="
												row.transaction.status.includes(
													'pending'
												) ||
												row.transaction.status.includes(
													'failed'
												)
													? 'col-2 text-danger '
													: 'col-2 text-success'"
										>
											{{row.transaction.status  }}
										</td>
										<td v-else class="col-1">No transaction found</td>
										<td>
											<div   v-if="row.transaction !=null"
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="viewClass(row.id)"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
											</div>
											<div v-else class="col-2 pl-0">
												<span >No course selected</span>
											</div>

										</td>
									</template>
								</base-table>
							</div>
							<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="count"
								v-model="currentPage"
								align="center"
							/>
						</card>
						<div class="row mt-4 d-flex justify-content-around">
							<div
								v-for="(data, index) in dashboardData"
								:key="index"
								class="card-sm custom-card rounded shadow bg-white py-3 my-3 my-sm-0 py-sm-3 col-sm mx-2 col-12"
							>
								<div
									:class="
										data.head === 'Payment Pending'
											? 'num-card col-3  ml-3 rounded text-center'
											: data.head === 'Demo Class Request'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
									:style="
										data.head === 'Payment Pending'
											? 'background-color:#F35D94'
											: data.head === 'Demo Class Request'
											? 'background-color:#9B51E0'
											: 'background-color:#9EBF6D'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<div v-if="data.head=='New students'||data.head=='Demo Class Request'" 
										@click.prevent="
											redirectTo(data.routeId)
										"
										class="btn col-2"
									>
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification
						:count="count"
						:current-page="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
		<Modal
			v-if="isOpen"
			:isOpen="isOpen"
			:closeEditModal="closeEditModal"
			:student-id='studentId'
		/>
		<ModalTwo
			v-if="addOpen"
			:addOpen="addOpen"
			:closeAddModal="closeAddModal"
		/>
	</div>
</template>
<script>
import Modal from "./Modals/Modal.vue";
import ModalTwo from "./Modals/Modal2.vue";
import Notification from '../../components/Notification.vue';

// import NotificationPanel from '@/layout/NotificationPanel.vue';
export default {
	name: "STMS",
	components: {
		Modal,
		ModalTwo,
		Notification
	},

	data() {
		return {
			isOpen: false,			
			itemsPerPage: 15,
			currentPage: 1,
			startIndex:0,
			addOpen: false,
			studentId: null,
			teacher:'Sathybhama',
			searchTerm:'',
		};
	},
	computed: {
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		tableHeader() {
			return this.$store.state.StmsData.tableHeaders;
		},
		tableData() {
			return this.$store.state.StmsData.tableData;
		},
		count(){
			return this.$store.state.StmsData.stmsCount;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "39px",
				width: "35px",
				background: "#2194f6",
			};
		},
		// pagedTableData() {
		// 	var start = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(start, start + this.itemsPerPage);
		// },
		dashboardData(){
			return this.$store.state.DashboardData.stmsCardData;
		},
		totalStudents(){
			return this.$store.state.DashboardData.total_students;
		},
		newStudents(){
			return this.$store.state.DashboardData.new_students
		}
	},
	methods: {
		openModal(id) {
			this.isOpen = !this.isOpen;
			this.studentId = id;		
		},
		closeEditModal() {
			this.isOpen = !this.isOpen;
			this.fetchSTMSList();
		},
		redirectTo(routeId) {
			if (routeId === 34) {
				this.$router.push("/demo-class");
			} else if (
				routeId === 33 &&
				this.$route.path != "/stms/new-students"
			) {
				this.$router.push("/stms/new-students");
			}
		},
		viewClass(studentID){
			this.$router.push('stms/class-details/'+studentID)
		},

		isAddModalOpen() {
			this.addModal = !this.addModal;
			this.fetchSTMSList();
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		closeAddModal() {
			this.addOpen = !this.addOpen;
			this.startIndex=0
			this.fetchSTMSList()
		},
		search(){
			if(this.searchTerm!==''){
				this.fetchSTMSList();
			}
		},
		fetchSTMSList() {
			this.$store
				.dispatch("fetchSTMSList",{searchTerm:this.searchTerm,itemsPerPage:this.itemsPerPage,startIndex:this.startIndex})
				.then(()=>{
				}).catch(err => {
					this.$notify({
						title: "Failed to fetch Stms",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchStudentDashboardData() {
			this.$store
				.dispatch("fetchStudentDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchParentList() {
			this.$store
				.dispatch("fetchParentList",{itemsPerPage:'',startIndex:''})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch users",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchTeachersList() {
			this.$store
				.dispatch("fetchTeachersList", {searchTerm: "",itemsPerPage: "",startIndex: "",})
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch Teachers",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		fetchNotificationsList() {
			this.$store
				.dispatch("fetchNotificationsList")
				.catch((err) => {
					this.$notify({
						title: "Failed to fetch notifications",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		}
	},
	watch:{
		searchTerm(){
			if(this.searchTerm==''){
				this.fetchSTMSList();
			}
		},
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchSTMSList();
		}

	},

	mounted(){
		
		this.fetchSTMSList();
		this.fetchStudentDashboardData();
		this.fetchParentList();
		this.fetchTeachersList();
		
	}
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}
.search #input {
	border-radius: 50px;
}
.custom-card {
	width: 250px !important;
	border-radius: 21px !important;
}

.num-card {
	border-radius: 13px !important;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 15vw;
	width: 10rem !important;
}
</style>
