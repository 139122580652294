<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
		<div class="pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div class="col-sm-12">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}	</h3>
								<div class="row d-flex justify-content-between">
									<h2 class="text-black col-10 font-weight-bold">
										Total Subjects
									</h2>
									<div class=" d-flex justify-content-end ml-auto mt-2   ">
										<base-button
											@click.prevent="addNew"
											type="primary"
											outline
											class="mr-3	 w-60"
											>Add New +</base-button
										>
									</div>
								</div>
							</div>
						</div>

						<card class="mt-3" :style="tableStyle" rounded>
							<div class="table-responsive ">
								<base-table
									class="align-items-center"
									:data="tableList"
								>
									<template slot="columns">
										<th>Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template
										class="row custom-row"
										slot-scope="{ row, index }"
									>
										<td class="col-1 sl-row">
											{{
												itemsPerPage * (currentPage - 1) +
												index +
												1
											}}
										</td>
										<td class="col-2 id-row">
											<div
												class="row d-flex justify-content-center align-items-center"
											>
												<div class="col-1 mr-4 pl-0">
													{{ row.id.slice(0,3) }}
												</div>
												<div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="
														openModal(row.id)
													"
												>
													<i
														class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"
													/>
												</div>
											</div>
										</td>
										<td class="col text-center">
											{{ row.name }}
										</td>
										<td class="col text-center">
											{{ row.total_students }}
										</td>
										<td class="col text-center">
											{{ row.total_teachers }}
										</td>
									</template>
								</base-table>
							</div>
							<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="subjectsCount"
								v-model="currentPage"
								align="center"
							/>
						</card>
						<div class="row mt-4 d-flex justify-content-around">
							<div
								v-for="(data, index) in cardData"
								:key="index"
								class="card-sm custom-card rounded shadow bg-white py-2 mb-3 mb-sm-0 col-12 col-sm-4"
							>
								<div
									:class="
										data.head === 'Total students'
											? 'num-card col-3  ml-3 rounded text-center'
											: data.head === 'Total teachers'
											? 'num-card col-3  ml-3 rounded text-center'
											: 'num-card col-3  ml-3 rounded text-center'
									"
									:style="
										data.head === 'Total students'
											? 'background-color:#F35D94'
											: data.head === 'Total teachers'
											? 'background-color:#9B51E0'
											: 'background-color:#9EBF6D'
									"
								>
									<h3
										class="py-2 mt-2 text-white font-weight-bold text-nowrap"
									>
										{{ data.count }}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div class="col">
											<span
												class="font-weight-bold text-nowrap"
												>{{ data.head }}</span
											>
										</div>
										<div class="col">
											<span class="text-sm nowrap">{{
												data.body
											}}</span>
										</div>
									</div>
									<div
										@click.prevent="redirectTo(data.head)"
										class="btn col-2"
									>
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="subjectsCount"
						:currentPage="currentPage"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- </base-header> -->
		<!--modal-->
				<modal
					class="modal"
					:show="isOpen"
					footerClasses="pt-0 pb-1"
					headerClasses="	"
					@close="isModalOpen"
					modal-classes=" modal-dialog-centered  modal-lg   "
					type="mini"
				>
					<template v-slot:header>
						<h3 class="col-6">Course Details</h3>
						<div class="Btn ml-lg-8 pl-lg-8">
							<base-button
								v-if="profileEdit"
								@click.prevent="profileEdit = !profileEdit"
								style="
									border-radius: 20px;
									background-color: #2f80ed;
								"
								class="hidden py-1 pl-4 pr-4"
								type="primary"
								size="sm"
								>Edit</base-button
							>
							<base-button
								v-else
								@click.prevent="profileEdit = !profileEdit"
								style="border-radius: 20px"
								class="visible py-1 px-3 bg-danger"
								type="danger"
								size="sm"
								>Cancel</base-button
							>
						</div>
					</template>
					<div>
						<form>
							<div class="form-group row">
								<label
									for="id"
									class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
									>ID</label
								>
								<div class="custom-input col-sm-2">
									<span class="py-0 text-sm">{{profileData.id && profileData.id.slice(0,3)}}</span>
								</div>
								<label
									for="Cname"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Course Name</label
								>
								<div
									:class="
										profileEdit
											? 'custom-input col-sm-3 '
											: 'custom-edit-input col-sm-3 '
									"
								>
									<input
										type="text"
										:readonly="profileEdit"
										class="form-control-plaintext py-0"
										id="Cname"
										v-model="loadedData.name"
									/>
								</div>
							</div>						
							<div class="form-group row">
								<label
									for="no_students"
									class="col-sm-3 col-form-label py-0 text-sm font-weight-bold"
									>No of Students</label
								>

								<div
									class="custom-input col-sm-2 d-flex"
								>
									<input
										readonly
										type="text"
										style="width: 300px"
										class="form-control-plaintext py-0"
										id="no_students"
										v-model="loadedData.total_students"
									/>
								</div>
								<label
									for="slot"
									class="col-sm-3 col-form-label py-0 text-sm font-weight-bold"
									>Available slots</label
								>

								<div
									:class="profileEdit? 'custom-input col-sm-3 ': 'custom-edit-input col-sm-3 '">
									<input
										:readonly="profileEdit"
										type="text"
										class="form-control-plaintext py-0"
										id="slot"
										v-model="loadedData.available_slots"
									/>
								</div>
							</div>
							<!-- <div class="form-group row">
								<label
									for="name"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Teachers</label
								>
								<div v-if="loadedData.teachers !=null&&loadedData.teachers.length"   class="col-sm-7">
									<button v-for="(teacher,idx) in loadedData.teachers" :key="idx"
										@click.prevent=""
										class="btn mb-2 text-white btn-sm bg-success text-nowrap"
									>
										{{teacher}}
									</button>
								</div>
									<div v-else class="col-sm-7 text-danger"><span>No teacher assigned for this subject</span></div>
								
							</div> -->
						</form>
					</div>
					<template v-slot:footer>
						<base-button
						v-if="!profileEdit"
							type="primary pl-4 pr-4"
							style="
								width: 180px;
								height: 46px;
								border-radius: 20px;
								background-color: #f35d94;
							"
							class="mb-2"
							@click.prevent="applyChanges"
							>Save</base-button
						>
					</template>
				</modal>

		<AddCourseModel
			v-if="addOpen"
			:addOpen="addOpen"
			:closeAddModal="closeAddModal"	
		/>
	</div>
</template>
<script>
// import NotificationPanel from '@/layout/NotificationPanel.vue';
import AddCourseModel from './Modal/AddCourseModel.vue';
import Notification from '../../components/Notification.vue';
export default {
	name: "Courses",
	components: {
		AddCourseModel,
		Notification,
	},

	data() {
		return {
			isOpen: false,
			profileEdit: true,
			loadedData: {},
			courseId: "",
			edit: true,
			itemsPerPage: 15,
			currentPage: 1,
			startIndex:0,
			addOpen: false,
			teacherData:{
				teacher:'',
				time:'10:00'
			},
			
		};
	},
	computed: {
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		tableHeader() {
			let data = this.$store.state.CoursesData.tableHeaders;
			return data;
		},
		fetchedData() {
			return this.$store.state.CoursesData;
		},
		tableList() {
			return this.fetchedData.fetchedData;
		},
		cardData(){
			return this.$store.state.DashboardData.SubjectCardData;
		},
		// totalTeachers(){
		// 	return this.$store.state.DashboardData.total_teachers;
		// },
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		profileData() {
			if (this.isOpen) {
				return this.tableList.find((data) => {
					return data.id === this.courseId;
				});
			} else {
				return this.tableList;
			}
		},
		subjectsCount(){
			return this.$store.state.CoursesData.subjectsCount;
		}
	},
	methods: {
		applyChanges() {
			var error = undefined;
			this.profileEdit=true;

			if (this.loadedData.name.trim() == "") {
				error = "Course name is required";
			}else if(this.loadedData.available_slots==0){
				error="Available slot is required"
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			
			this.$store
				.dispatch("setCourseDetails", {courseData:this.loadedData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen()
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to edit course",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchCourseList() {
			this.$store.dispatch("fetchCourseList",{itemsPerPage:this.itemsPerPage,startIndex:this.startIndex}).catch((err) => {
				this.$notify({
					title: "Failed to fetch course list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		checkCourseDetails() {
			if (this.courseId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid course ID " + this.courseId,
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch(
								"fetchCourseDetails",
								this.courseId
							)
							.then(() => {
								this.prepareCourse();
							})
							.catch((err) => {
								this.$notify({
									title: "Failed to fetch course details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString(),
								});
							});
					} else {
						this.prepareCourse();
					}
				}
			}
		},
		prepareCourse() {
			this.loadedData = JSON.parse(JSON.stringify(this.tableList.find(item=>item.id===this.courseId)));
		},
		redirectTo(head) {
			if (head === "Total students") {
				this.$router.push("/stms");
			}else{
				this.$router.push("/tms")
			}
		},
		isModalOpen() {
			this.isOpen = !this.isOpen;
			this.fetchCourseList();

		},
		openModal(id) {
			this.courseId = id;
			this.isOpen = !this.isOpen;		
			this.checkCourseDetails()
		},
		addNew() {
			this.addOpen = !this.addOpen;
		},
		closeAddModal() {
			this.addOpen = !this.addOpen;
			this.fetchCourseList();
		},
		fetchStudentDashboardData() {
			this.$store
				.dispatch("fetchStudentDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchTeacherDashboardData() {
			this.$store
				.dispatch("fetchTeacherDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
	},
	watch:{
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchCourseList();
		}
	},

	mounted() {
		this.fetchStudentDashboardData();
		this.fetchTeacherDashboardData();
		this.fetchCourseList();

		// this.fetchTeachersList()
	},
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}
.notif-card {
	min-width: 18vw;
	min-height: 100%;
	background-color: #fff2f8;
	border-top-left-radius: 50px;
	border: none;
}
.custom-card {
	width: 250px !important;
	border-radius: 21px !important;
}
.num-card {
	border-radius: 13px !important;
}
.custom-input input {
	width: 250px !important;
	height: 21px;
	outline: none;
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input input:focus {
	outline: none;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.custom-multi-select  >>> .multiselect__tags {
	border: 0px !important;
    margin: 0px !important;
	margin-bottom: 3px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}

/* So that webkit doesn't mess up */
.sl-row {
	display: -webkit-inline-box !important;
}
.id-row {
	min-width: 10vw;
	width: 100rem !important;
}
</style>
