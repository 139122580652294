<template>
	<div>
		<!-- <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center"> -->
			<div class="container-fluid pb-6 pt-7 pt-lg-8 d-flex flex-column align-items-center">
			<span class="mask bg-white"></span>

			<div class="container-fluid d-flex flex-column">
				<div class="row">
					<div :class="notification?'col-md-10 col-sm-10':'col-md-12 col-sm-12'">
						<div class="row">
							<div class="col-sm-12">
								<h3 class="text-orange">Hey {{$store.state.profileData.data.name}}</h3>
								<h2 class="text-black font-weight-bold">
									New {{newStudents}} students joined in this week
								</h2>
							</div>
						</div>

						<card class=" mt-3" :style="tableStyle" rounded>
							<div v-if="tableData.length >0" class="table-responsive">
								<base-table
									class="align-items-center"
									:data="tableData"
								>
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
									<template class="row" slot-scope="{ row ,index}">
										<td class="pl-0">{{
												itemsPerPage *
													(currentPage - 1) +
												index +
												1
											}}</td>
										<td class="col-2 id-row">
											<div class="row d-flex justify-content-center align-items-center">
												<div class="col-1 mr-5 ">
													{{ row.id.slice(0,4) }}
												</div>
												<!-- <div
													:style="idButtonStyle"
													class="btn col-2"
													@click.prevent="openModal(row.id)">
													<i class="fa fa-angle-right text-white d-flex justify-content-center align-items-center"/>
												</div> -->
											</div>
										</td>
										<td class="col-4">
											<div
												class="row d-flex mr-2 align-items-center"
											>
												<div class="col-1">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div class="col-2">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{row.name}}</span>
													</div>
													<div class="col-12">
														<span
															>{{row.student_class}} standard, age
															{{row.age}}</span
														>
													</div>
												</div>
											</div>
										</td>
										<td class="col-4">
											<div
												v-if="row.teacher !=null"
												class="row d-flex  align-items-center"
											>
												<div class="col-1">
													<img
														src="img/admin.jpg"
														width="40px"
														height="40px"
														alt=""
														class="rounded-circle"
													/>
												</div>
												<div  class="col-9">
													<div class="col-12">
														<span
															class="font-weight-bold text-black"
															>{{row.teacher.name}}</span>
													</div>
													<div class="col-12">
														<span
															>{{row.teacher.qualification}}</span
														>
													</div>
												</div>
												
											</div>
											<!-- <div v-else class="row">
												<div
													:style="assignTeacherButtonStyle"
													class="btn col-2 ml-3 rounded-circle d-flex justify-content-center align-items-center"
												>
													<i
														class="fa fa-plus text-white" 
													/>
												</div>
												<div
													class="d-flex align-items-center"
												>
													<span
														class="text-primary font-weight-bold"
														>Assign Teacher</span
													>
												</div>
											</div> -->
											<div v-else><span class="text-warning pl-2">No course selected</span></div>
										</td>
										<td v-if="row.transaction !=null"
										:class="row.transaction.status=='success'?'col-1 text-success':'col-1 text-warning'"	
										>
											{{row.transaction.status}}
										</td>
										<td v-else>No transactions</td>
									</template>
								</base-table>
							</div>
							<div v-else class="table-responsive">
								<base-table class="align-items-center">
									<template slot="columns">
										<th class="pl-0">Sl</th>
										<th
											class="text-center font-weight-bold text-black"
											v-for="(
												header, index
											) in tableHeader"
											:key="index"
										>
											{{ header }}
										</th>
									</template>
								</base-table>
							<div  class="col-12 d-flex flex-column justify-content-center align-items-center">
									<img src="img/undraw.png" height="250px" width="250px" alt="No data found">
									<h3 class="text-muted mr-4">Oops! No data found</h3>
							</div>
								
						</div>
						<base-pagination
								class="mt-5"
								:perPage="itemsPerPage"
								:total="newStudents"
								v-model="currentPage"
								align="center"
							/>

						</card>
							<div class="row mt-4 d-flex justify-content-between">
							<div class="card-sm custom-card rounded shadow bg-white py-3 col-12 col-sm-4">
								<div class=" num-card col-3  ml-3 rounded text-center"
								style="background-color:#9EBF6D"
								>
									<h3 class="py-2 mt-2 text-white font-weight-bold text-nowrap">
										{{totalStudents}}
									</h3>
								</div>
								<div class="row mt-2">
									<div class="col-9">
										<div  class="col">
											<span class="font-weight-bold text-nowrap">Total students</span>
										</div>
										<div class="col">
											<span class="text-sm nowrap">Total {{totalStudents}} students</span>
										</div>
									</div>
									<div @click.prevent="redirectTo" class="btn col-2">
										<i class="fa fa-angle-right"></i>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div v-if="notification"  class="col-2 notif-section" >
						<Notification 
						:count="newStudents"
						:current-page="currentPage"
						/>
					</div>
				</div>
			</div>
			</div>
		<!-- </base-header> -->
		<!--modal-->
		<!-- <NewStudentsModal
        :is-open="isOpen"
		:is-modal-open="isModalOpen"
		:profile-data="profileData"
        /> -->
	</div>
</template>
<script>
// import NewStudentsModal from "./Modals/NewStudentsModal.vue";
import Notification from '../../components/Notification.vue';


export default {
	name: "STMS",
	components: {
    // NewStudentsModal,
	Notification
},

	data() {
		return {
			isOpen: false,
			studentId:'',
			itemsPerPage:15,
			currentPage:1,
			startIndex:0		
		};
	},
	computed: {
		notification(){
			return this.$store.state.notifications.notificationStatus;

		},
		tableHeader(){
			return this.$store.state.NewStudentsData.tableHeaders;
		},
		tableData(){
			return this.$store.state.NewStudentsData.tableData;
		},
		tableStyle() {
			return {
				background: "white",
				borderRadius: "50px",
			};
		},
		idButtonStyle() {
			return {
				background: "#2194f6",
				borderRadius: "10px",
			};
		},
		assignTeacherButtonStyle() {
			return {
				height: "39px",
				width: "34px",
				background: "#2194f6",
			};
		},
		profileData(){
			if(this.isOpen){
			return this.tableData.find(data=>{
				return 	data.id===this.studentId;
				});
			}else{
			return	this.tableData;
			}
		},
		totalStudents(){
			return this.$store.state.DashboardData.total_students;
		},
		newStudents(){
			return this.$store.state.DashboardData.new_students
		}
		
	},
	methods: {
		openModal(id){
			this.isOpen=!this.isOpen;
				this.studentId=id;		
			
		},
		redirectTo(){
				this.$router.push('/stms')
			
		},
		isModalOpen(){
           this.isOpen=!this.isOpen
		},
		fetchStudentDashboardData() {
			this.$store
				.dispatch("fetchStudentDashboardData")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch Data",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		fetchNewStudentsList() {
			this.$store
				.dispatch("fetchNewStudentsList",{itemsPerPage:this.itemsPerPage,startIndex:this.startIndex})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch students",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},		
	},
	watch:{
		currentPage(){
			this.startIndex=((this.currentPage - 1) * this.itemsPerPage);
			this.fetchNewStudentsList();
		}
	},

	mounted() {
		this.fetchNewStudentsList();
		this.fetchStudentDashboardData();
	},
};
</script>
<style scoped>
::placeholder {
	font-family: SF Compact Display;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	color: #333333;
	opacity: 0.5;
}

.custom-card {
	width: 250px !important;
	border-radius: 21px !important;
}
.num-card {
	border-radius: 13px !important;
}
.search #input{
	border-radius: 50px;
	width:360px;

}
@media only screen and (max-width: 600px) {
	.notif-section {
		display: none;
	}
	.head-section {
		display: flex;
		flex-direction: column;
	}
}
.id-row {
	min-width: 12vw;
	width: 100rem !important;
}
</style>
