<template>
	<div>
		<base-header class="header pb-8 pt-lg-8 pt-8 d-flex align-items-center">
			<!-- Mask -->
			<span class="mask bg-white"></span>
			<!-- Header container -->
			<div class="container-fluid align-items-center">
				<div class="row">
					<div class="col">
						<h1 class="display-3">Admins</h1>
						<p class="mt-0 mb-0">See all registered admins here</p>
					</div>
					<div class="col-12 col-sm-3 mt-4">
						<base-input
							placeholder="Search Users"
							class="input-group-alternative"
							alternative=""
							v-model="userSearch"
							addon-right-icon="fas fa-search"
						></base-input>
					</div>
				</div>
			</div>
		</base-header>

		<div class="container-fluid mt--6">
			<div class="row">
				<div
					class="card user-card col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-5 card-profile d-flex justify-content-center align-items-center shadow"
					@click.prevent="adminAdd = true"
				>
					<div
						style="
							width: 59px;
							height: 59px;
							border-radius: 95px;
							background: white;
							border-color: black;
						"
						class="card rounded-circle d-flex justify-content-center align-items-center"
					>
						<i class="fa fa-plus p-0 text-black" />
					</div>
				</div>
				<div
					class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-5"
					v-for="(user, index) in filteredUserList"
					:key="index"
				>
					<div class="card user-card card-profile shadow">
						<img
							src="img/userIcon.png"
							height="100"
							width="100"
							class="mx-auto mt-2 rounded-circle"
						/>
						<div class="card-body pt-2">
							<div class="text-center">
								<h3>{{ user.name }}</h3>
								<base-button
									size="sm"
									type="danger"
									class="mt-3"
									@click.prevent="deleteUser(index)"
									>Delete
								</base-button>
								<base-button
									size="sm"
									type="primary"
									class="mt-3"
									@click.prevent="
										toggleUserInfoDialog(user.id)
									"
									>View</base-button
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal
			:show="adminAdd"
			@close="closeAddModal"
			body-classes="p-0"
			modal-classes="modal-dialog-centered modal-sm"
		>
			<card
				type="secondary"
				shadow
				header-classes="bg-white"
				body-classes="px-lg-5 "
				class="border-0"
			>
				<template v-slot:header>
					<div class="text-muted text-center mb-1">
						<h3 class="text-muted">Create new account</h3>
					</div>
				</template>
				<form role="form">
					<base-input
						formClasses="input-group-alternative mb-3"
						type="text"
						placeholder="Name"
						addon-left-icon="ni ni-circle-08"
						v-model="newUser.name"
					>
					</base-input>
					<base-input
						formClasses="input-group-alternative mb-3"
						placeholder="Email"
						addon-left-icon="ni ni-email-83"
						v-model="newUser.email"
					>
					</base-input>
					<base-input
						formClasses="input-group-alternative mb-3"
						type="number"
						placeholder="Mobile"
						addon-left-icon="ni ni-mobile-button"
						v-model="newUser.mobile"
					>
					</base-input>
					<base-input
						formClasses="input-group-alternative mb-3"
						type="text"
						placeholder="Username"
						addon-left-icon="ni ni-single-02"
						v-model="newUser.username"
					>
					</base-input>
					<base-input
						formClasses="input-group-alternative mb-3"
						v-model="newUser.password"
						type="password"
						placeholder="Password"
						addon-left-icon="ni ni-lock-circle-open"
					>
					</base-input>

					<div class="text-center">
						<base-button
							@click.prevent="adminAdd = !adminAdd"
							size="sm"
							type="primary"
							outline
							class="my-2"
							>Cancel</base-button
						>
						<base-button
							@click.prevent="addNewUser"
							size="sm"
							type="success"
							class="my-2"
							>Create</base-button
						>
					</div>
				</form>
			</card>
		</modal>
		<delete-dialog
			:data="deleteTableData"
			v-model="deleteItemIndex"
			actionString="deleteUser"
			:item="item"
		>
			<span slot="warning"
				>All data associated with this user will get deleted as
				well!</span
			>
		</delete-dialog>
		<!-- info modal -->
		<InfoModal
			v-if="showUserInfoDialog"
			:is-open="showUserInfoDialog"
			:close-modal="closeModal"
			:user-id="selectedUserID"
		/>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import InfoModal from "./UsresModals/InfoModal.vue";
import DeleteDialog from "../components/ItemDeleteDialoge.vue";
export default {
	components: {
		InfoModal,
		DeleteDialog,
	},
	data() {
		return {
			adminAdd: false,
			deleteItemIndex: -1,
			deleteTableData: [],
			displayLoader: false,
			showUserInfoDialog: false,
			selectedUserID: null,
			userSearch: "",
			item: "",
			newUser: {
				name: "",
				email: "",
				mobile: "",
				username: "",
				password: "",
			},
		};
	},
	computed: {
		usersData() {
			return this.$store.state.profileData.newUserList;
		},
		filteredUserList() {
			var filteredUserList = this.usersData;
			var filterUserSearch = this.userSearch.toLowerCase();

			if (filterUserSearch !== "") {
				filteredUserList = filteredUserList.filter((user) =>
					user.name.toLowerCase().includes(filterUserSearch)
				);
			}

			return filteredUserList;
		},
	},
	methods: {
		closeAddModal() {
			this.adminAdd = !this.adminAdd;
			this.fetchNewUsersList();
		},
		addNewUser() {
			var error = undefined;
			if (this.newUser.name.trim() == "") {
				error = "Name is required";
			} else if (this.newUser.email == "") {
				error = "Email is required";
			} else if (this.newUser.mobile == "") {
				error = "Mobile number is required";
			} else if (this.newUser.username == "") {
				error = "Username is required";
			} else if (this.newUser.password == "") {
				error = "Password is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error,
				});
				return;
			}
			this.$store
				.dispatch("setNewUser", {
					Data: this.newUser,
				})
				.then(() => this.closeAddModal())
				.catch((err) => {
					this.$notify({
						title: "Failed to add new user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		toggleUserInfoDialog(userID) {
			this.showUserInfoDialog = !this.showUserInfoDialog;
			this.selectedUserID = userID;
		},
		closeModal() {
			this.showUserInfoDialog = !this.showUserInfoDialog;
		},
		fetchNewUsersList() {
			this.$store.dispatch("fetchNewUsersList").catch((err) => {
				this.$notify({
					title: "Failed to fetch users list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				});
			});
		},
		deleteUser(index) {
			this.item = this.usersData[index].name;
			this.deleteItemIndex = index;
			this.deleteTableData = this.usersData;
		},
	},
	mounted() {
		this.fetchNewUsersList();
	},
};
</script>
<style scoped>
.user-card:hover {
	cursor: pointer;
	box-shadow: 0.2px 0.2px !important;
}
</style>
