<template>
	<div>
		<modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="closeModal"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="col-6 pl-0">
					Parent Profile 
					<!-- <span class="text-orange"> Interview Pending</span> -->
				</h3>
				<div class="Btn ml-lg-8 pl-lg-8">
					<base-button v-if="profileEdit"
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								background-color: #2f80ed;
							"
							class="hidden py-1 px-4"
							type="primary"
							size="sm"
							
							>Edit</base-button
						>
					<base-button v-else
							@click.prevent="profileEdit = !profileEdit"
							style="
								border-radius: 20px;
								
							"
							class="visible py-1 px-3 bg-danger"
							type="danger"
							size="sm"
							
							>Cancel</base-button
						>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-sm-9">
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="id"
								:value="profileData.id.slice(0,3)"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-4 ':'custom-edit-input col-sm-4 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="name"
								v-model="parentData.name"
							/>
						</div>
					</div>
					<div class="form-group row">
								<label
									for="student"
									class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
									>Student name</label
								>
								<div class="col-sm-7">
									<button @click.prevent="" v-for="(children,idx) in parentData.children" :key="idx"
										class="btn mb-2 text-white btn-sm bg-success text-nowrap"
									>
										{{children.name}}
									</button>
								</div>
							</div>
                    <div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Gender</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '">
							<input
							v-if="profileEdit"
								type=" text"
								readonly
								class="form-control-plaintext py-0"
								id="gender"
								:value="parentData.gender"
							/>
							<select	
							v-else
							:disabled="profileEdit"					
								class="form-control input-group-alternative py-0 pl-1 text-dark"
								style="width: 180px; height: 26px"
								v-model="parentData.gender"
								id="gender">
								<option selected disabled value="">Choose</option>
								<option value="male">Male</option>
								<option value="female">Female</option> 
								<option value="other">Other</option>
	
							</select>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>State</label
						>
						<div :class="profileEdit ? 'custom-edit-input col-sm-3 pl-2':'custom-input col-sm-3 pl-2'">	
							<!-- <span class="pl-1 ml-1 text-sm" v-if="profileEdit&&isStateLoaded">{{states.filter(state=>state.id==teacherData.state)[0].state}}</span> -->
							<select							
								class="form-control-plaintext input-group-alternative py-0 pl-1 text-dark"
								style="width: 180px; height: 26px"
								v-model="parentData.state"
								:disabled="profileEdit"
								id="state">
								<option v-for="(state,idx) in states" :key="idx" :value="state.id">{{state.state}}</option>
							</select>
						</div>
						<label
							for="district"
							class="py-0 col-sm-2 text-sm col-form-label font-weight-bold"
							>District</label
						>
						<div class="custom-input col-sm-3">
							<input
							v-if="profileEdit"
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="district"
								:value="parentData.district_name"
							/>
							<select	
							v-else						
								class="form-control-plaintext input-group-alternative py-0 pl-1 text-dark"
								style="width: 180px; height: 26px"
								id="district"
								@change="onChange($event)"
								>
								<option selected :value="parentData.district">{{parentData.district_name}}</option>
								<option v-for="(district,idx) in districts" :key="idx" :value="district.id">{{district.district}}</option>	
							</select>
						</div>
						
					</div>
					<div class="form-group row">
						<label
							for="location"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Location</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '" >
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="location"
								v-model="parentData.location"
							/>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="parentData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div :class="profileEdit ? 'custom-input col-sm-3 ':'custom-edit-input col-sm-3 '">
							<input
								type="text"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="parentData.whatsapp_number"
							/>
						</div>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
				v-if="!profileEdit"
					type="primary pl-4 pr-4"
					style="
						width: 180px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2"
					@click.prevent="profileSave"
					>Save</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>

export default {
props:['isOpen','closeModal',"parentId"],
    data(){
        return{
            profileEdit:true,
			parentData:{
				name:'',
				mobile:'',
				gender:'',
				whatsapp_number:'',
				district:null,
				location:'',
				state:null,
				district_name:'',
				children:''
			}
        }
    },
    computed:{
        tableData() {
			return this.$store.state.ParentData.parent_tableData;
		},
        profileData() {
			return this.tableData.find((data) => {
				return data.id === this.parentId;
			});
		},
		isLoaded(){
			return this.$store.state.ParentData.isLoaded;
		},
		districts(){
			return this.$store.state.LocationData.districts;
		},
		isStateLoaded(){
			return this.$store.state.LocationData.isLoaded;
		},
		states(){
			return this.$store.state.LocationData.states;
		},
		count(){
			return this.$store.state.ParentData.parentCount;
		},
    },
	methods:{
		profileSave() {
			var error =undefined;
			if(! this.isLoaded){
				error = "Data is not loaded yet, try again later";
				this.fetchParentAndCheck();
			}else if(this.parentData.name==''){
				error='username is required';
			}else if(this.parentData.gender==''){
				error='Gender must be selected';
			}else if(this.parentData.state==null){
				error='District must be selected';
			} else if(this.parentData.district==null){
				error='District must be selected';
			}else if(this.parentData.location==''){
				error='Location is required';
			}else if(this.parentData.mobile==''){
				error='Mobile number is required';
			} else if(this.parentData.whatsapp_number==''){
				error='Whatsapp number is required';
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.$store
				.dispatch("setParentDetails", {
					parentData:this.parentData
				})
				.then(() =>this.closeModal())
				.catch(err => {
					this.$notify({
						title:
							"Failed to edit user",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});			
			
		},
		onChange(event){
			this.parentData.district=event.target.value;
		},
		fetchParentAndCheck() {
			this.$store
				.dispatch("fetchParentList",{itemsPerPage:this.count,startIndex:''})
				.then(() => {
					this.checkParentDetails();
				})
				.catch(err => {
					this.$notify({
						title: "Failed to fetch user data for verification",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
		},
		checkParentDetails() {
			if (this.parentId !== undefined) {
				if (this.profileData == undefined) {
					this.$notify({
						title: "Error",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: "Invalid User ID " + this.parentId
					});
					return;
				} else {
					if (!this.profileData.detailsloaded) {
						this.$store
							.dispatch("fetchParentDetails", this.parentId)
							.then(() => {
								this.prepareStudent();
							})
							.catch(err => {
								this.$notify({
									title: "Failed to fetch user details",
									icon: "fa fa-exclamation-triangle",
									type: "danger",
									message: err.toString()
								});
							});
					} else {
						this.prepareStudent();
					}
				}
			}
		},
		prepareStudent() {
			this.parentData = JSON.parse(JSON.stringify(this.profileData));
		},
		fetchDistrictList() {
			this.$store
				.dispatch("fetchDistrictList",this.parentData.state)
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
		fetchStateList() {
			this.$store
				.dispatch("fetchStateList")
				.catch(err => {
					this.$notify({
						title: "Failed to fetch state",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
				});
			});
		},
	},
	watch:{
		'parentData.state'(){
				this.fetchDistrictList();
		}
	},
    mounted() {
		if (!this.isLoaded) {
			this.fetchParentAndCheck();
		} else {
			this.checkParentDetails();			
		}
		this.fetchStateList();
	},
}
</script>
<style scoped>
.custom-input input{
    width: 250px !important;
	height: 21px;
	outline: none;
	
}
.custom-edit-input input{

	border: none;
    border-bottom: 1px solid #121619;
    color: black;
	transition: .3s all linear
    
}
.custom-input select{
	outline: none;
}
.custom-edit-input select{
	border: none;
    outline: none;
	box-shadow: none;
    
}
.custom-edit-input input:focus{
	outline: none;
	}
.visible {
  visibility: visible;
  transition: opacity 2s linear;
}
.hidden {
  transition: visibility 0s 2s, opacity 2s linear;
}


</style>