<template>
	<div>
		<modal
			class="modal"
			:show="isOpen"
			footerClasses="pt-0 pb-1"
			headerClasses="	"
			@close="isModalOpen"
			modal-classes=" modal-dialog-centered  modal-lg   "
			type="mini"
		>
			<template v-slot:header>
				<h3 class="col-6">
					Teacher Profile :
					<span class="text-orange"> Interview Pending</span>
				</h3>
				<div class="Btn ml-lg-8 pl-lg-8">
					<base-button
						v-if="profileEdit"
						@click.prevent="profileEdit = !profileEdit"
						style="border-radius: 20px; background-color: #2f80ed"
						class="hidden py-1 pl-4 pr-4"
						type="primary"
						size="sm"
						>Edit</base-button
					>
					<base-button
						v-else
						@click.prevent="profileEdit = !profileEdit"
						style="border-radius: 20px"
						class="visible py-1 px-3 bg-danger"
						type="danger"
						size="sm"
						>Cancel</base-button
					>
				</div>
			</template>
			<div>
				<form>
					<div class="form-group row">
						<label
							for="id"
							class="col-sm-3 text-sm col-form-label py-0 font-weight-bold"
							>ID</label
						>
						<div class="custom-input col-sm-9">
							<span class="text-sm" v-if="profileData.id">{{profileData.id.slice(0,3)}}</span>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Name</label
						>
						<div
							class="custom-input col-sm-3"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.name"
							/>
						</div>
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Qualification</label
						>
						<div
							class="custom-input col-sm-3"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.qualification"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Address</label
						>
						<div
							class="custom-input col-sm-6"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="name"
								v-model="profileData.address"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Mobile Number</label
						>
						<div
							class="custom-input col-sm-3"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="mobile"
								v-model="profileData.mobile"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Whatsapp Number</label
						>
						<div
							class="custom-input col-sm-3"
						>
							<input
								type="text"
								readonly
								class="form-control-plaintext py-0"
								id="whatsapp"
								v-model="profileData.whatsapp_number"
							/>
						</div>
					</div>

					<div class="form-group row">
						<label
							for="mobile"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Interview Date</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-sm-3 '
									: 'custom-edit-input col-sm-3 '
							"
						>
							<input
								type="date"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="mobile"
								:v-on="loadedData.date"
								:value="profileData.date"
							/>
						</div>
						<label
							for="whatsapp"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Interview Time</label
						>
						<div
							:class="
								profileEdit
									? 'custom-input col-sm-3 '
									: 'custom-edit-input col-sm-3 '
							"
						>
							<input
								type="time"
								:readonly="profileEdit"
								class="form-control-plaintext py-0"
								id="whatsapp"
								:v-on="loadedData.time"
								:value="profileData.time"
							/>
						</div>
						
					</div>
					<div class="form-group row">
						<label
							for="link"
							class="col-sm-3 col-form-label text-sm py-0 font-weight-bold"
							>Interview Link</label
						>
						<div
							:class="
								edit
									? 'custom-input col-sm-4 d-flex '
									: 'custom-edit-input col-sm-4 d-flex '
							"
						>
							<input
								v-if="loadedData.meet_url !=null&&loadedData.meet_url.length>0 || !edit"
								type="url"
								:readonly="edit"
								style="width: 300px"
								class="form-control-plaintext py-0 text-primary"
								id="link"
								v-model="loadedData.meet_url"
							/>
							<div class="col-sm-2">
								<base-button
									v-if="edit"
									class=""
									@click="edit = !edit"
									style="
										background-color: #2f80ed;
										border-radius: 20px;
									"
									type="primary"
									size="sm"
									>Update</base-button
								>
								<base-button
									v-else
									class="ml-2 bg-success"
									@click="edit = !edit"
									style="border-radius: 20px"
									type="primary"
									size="sm"
									>Save</base-button
								>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label
							for="name"
							class="py-0 col-sm-3 text-sm col-form-label font-weight-bold"
							>Subjects</label
						>
						<div v-if="profileData.teacher !=null"  class="col-sm-9">
							<button v-for="(subject,idx) in profileData.teacher.subjects" :key="idx" class="btn text-white btn-sm bg-success text-nowrap">
								{{subject.name}}
							</button>							
						</div>
						<span v-else>No teacher found</span>
					</div>
				</form>
			</div>
			<template v-slot:footer>
				<base-button
					type="primary pl-4 pr-4"
					style="
						width: 200px;
						height: 46px;
						border-radius: 20px;
						background-color: #f35d94;
					"
					class="mb-2 text-nowrap px-2"
					@click.prevent="applyChanges"
					>Approve</base-button
				>
			</template>
		</modal>
	</div>
</template>
<script>
export default {
	props: ["isOpen", "isModalOpen", "profileData"],
	data() {
		return {
			loadedData: {
				id:"",
				date:"",
				time:"",
				meet_url:"",
				admin_approval:false
			},
			profileEdit: true,
			edit: true,
			studentValue: ["Sinan"],
			studentList: ["Sinan", "Davis", "Jiya", "Brad"],
		};
	},
	computed: {
	},
	methods: {
		applyChanges() {
			var error = undefined;

			if (this.profileData.date.trim() == "") {
				error = "Interview date is required";
			} else if (this.profileData.time.trim() == "") {
				error = "Interview time is required";
			} else if (this.loadedData.meet_url.trim() == "") {
				error = "Interview URL is required";
			}
			if (error) {
				this.$notify({
					title: "Error",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error
				});
				return;
			}
			this.loadedData.id = this.profileData.id
			this.loadedData.date = this.profileData.date
			this.loadedData.time = this.profileData.time
			this.loadedData.admin_approval = true

			console.log("final: ",this.loadedData)
			
			this.$store
				.dispatch("setNewApplicantStatus", {applicantData:this.loadedData})
				.then(() => {
					// this.$router.back()
					this.isModalOpen();
					this.InterviewCompletedCount();
					this.$store.dispatch('fetchNotificationsList');
					})
				.catch(err => {
					this.$notify({
						title:
							"Failed to edit applicant",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString()
					});
				});
			
		},
		assign(){
			this.loadedData.date = this.profileData.date
			this.loadedData.time = this.profileData.time
		},
		InterviewCompletedCount() {
			this.$store.dispatch("InterviewCompletedCount").catch((err) => {
				this.$notify({
					title: "Failed to fetch interview completed list",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: err.toString(),
				})
			})

		},
	},
	mounted() {
		if(this.profileEdit){
			this.assign()
		}
	},
};
</script>
<style scoped>
.custom-input input {
	width: 250px !important;
	height: 21px;
	outline: none;
}
.custom-edit-input input {
	border: none;
	border-bottom: 1px solid #121619;
	color: black;
	transition: 0.3s all linear;
}
.custom-edit-input input:focus {
	outline: none;
}
.visible {
	visibility: visible;
	transition: opacity 2s linear;
}
.hidden {
	transition: visibility 0s 2s, opacity 2s linear;
}
.custom-multi-select >>> .multiselect__tags {
	border: 0px !important;
	margin: 0px !important;
	outline: opx !important;
	box-shadow: none !important;
	color: black !important;
	transition: 0.3s all linear !important;
	padding-left: 0;
}
</style>
